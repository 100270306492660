import React, { Component, Fragment } from "react";
import {
  Welcome_to_AIRR,
  text_style_1,
  Start_an_Assessment,
  Dont_have_an_account,
  Signin,
  Forgot_password,
  Demo_2,
  Submit,
  TopMargin,
  Signup,
  Arrow,
  Info,
  ModelBackground,
  Model,
  ModelHeader,
  ModelText
} from "./Register.module.scss";
import { connect } from "react-redux";
import { UpdateUser } from "../Actions/UsersActions";
import { SignUp, SignIn } from "../Util/Auth";
import Select from "react-select";
import { SignOut, isAuthenticated } from "../Util/Auth";
import { withRouter, Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import { toLowerCase } from "../Util/Validate";
import _ from "lodash";

const options = [
  { label: "Petrochemical/Refining", value: "Petrochemical/Refining" },
  { label: "Chemical Processing", value: "Chemical Processing" },
  {
    label: "Storage/Pipeline/Transportation",
    value: "Storage/Pipeline/Transportation"
  },
  {
    label: "Oil & Gas Exploration/Production",
    value: "Oil & Gas Exploration/Production"
  },
  {
    label: "Power Generation/Utilities",
    value: "Power Generation/Utilities"
  },
  { label: "Discreet Manufacturing", value: "Discreet Manufacturing" },
  { label: "Food/Pharmaceuticaln", value: "Food/Pharmaceuticaln" },
  {
    label: "Industrial Services/Products",
    value: "Industrial Services/Products"
  },
  {
    label: "Other (Specify industry in the field below)",
    value: "Other (Specify industry in the field below)"
  }
];

class Register extends Component {
  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  state = {
    loading: false,
    firstName: "",
    lastName: "",
    jobTitle: "",
    departmentName: "",
    companyName: "",
    location: "",
    industryCategory: "",
    otherIndustryCategory: "",
    workemail: "",
    password: "",
    confirmpassword: "",
    nexstep: false,
    agree: false,
    updateSuccess: false,
    pass01type: true,
    pass02type: true
  };

  checkIndustry = () =>
    _.isEqual(
      this.state.industryCategory,
      "Other (Specify industry in the field below)"
    );

  toggle = () => this.setState(prevState => ({ agree: !prevState.agree }));

  handleOnChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value, errorMessage: null });
  handleChange = ({ target: { value } }) =>
    this.setState({ industryCategory: value });

  cognitoRegisterUser = () => {
    let { workemail, password } = this.state;
    if (password.length < 6) {
      this.setState({
        errorMessage: "Your password must be between 6 and 30 characters"
      });
      return;
    }
    SignUp(
      {
        username: toLowerCase(workemail),
        password: password
      },
      (error, success) => {
        if (error) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ loading: true }, () => {
            SignIn(
              {
                username: toLowerCase(workemail),
                password: password
              },
              (error, success) => {
                if (error) {
                  this.setState({ errorMessage: error.message });
                } else {
                  this.setState({
                    password: "",
                    confirmpassword: "",
                    loading: false,
                    nexstep: true
                  });
                }
              }
            );
          });
        }
      }
    );
  };

  /// logic to route to login after registration
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.updateSuccess) {
      return { loading: false, updateSuccess: true };
    }
    return null;
  }

  /// logic to route to login after registration
  componentDidUpdate(prevProps, prevState) {
    if (prevState.updateSuccess !== this.state.updateSuccess) {
      SignOut(() => {
        this.props.toastManager.add(
          "Successfully Registerd",
          {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: true
          },
          () => {
            this.routeTo("login");
          }
        );
      });
    }
  }

  showPassword = type =>
    this.setState(state => {
      return { [type]: !state[type] };
    });

  updateTheUser = cb => {
    this.setState({ loading: true });
    let {
      firstName,
      lastName,
      departmentName,
      companyName,
      location,
      industryCategory,
      otherIndustryCategory,
      jobTitle
    } = this.state;
    this.props.updateUser(
      {
        firstName: firstName,
        lastName: lastName,
        departmentName: departmentName,
        companyName: companyName,
        location: location,
        industryCategory:
          otherIndustryCategory !== ""
            ? otherIndustryCategory
            : industryCategory,
        jobTitle: jobTitle
      },
      cb
    );
  };

  info = () => {
    let { workemail, nexstep } = this.state;
    return workemail !== "" && nexstep ? (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <span className={Welcome_to_AIRR}>More info</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span className={Start_an_Assessment}>
              AIRR would like to know more details about you. This may be
              helpfull for AIRR to contact you.
            </span>
          </div>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <span className={Welcome_to_AIRR}>
              Welcome to <span className={text_style_1}>AIRR</span>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span className={Start_an_Assessment}>
              Start an Assessment and answer a series of questions to check the
              readiness of your company to invest in Artificial Intelligence.
            </span>
          </div>
        </div>
      </Fragment>
    );
  };

  registered = () => {
    let {
      firstName,
      lastName,
      companyName,
      workemail,
      nexstep,
      industryCategory,
      otherIndustryCategory,
      loading,
      agree,
      pass01type,
      pass02type,
      password,
      confirmpassword
    } = this.state;
    return workemail !== "" && nexstep ? (
      <form>
        <div className="form-row">
          <div className="form-group col-md-6">
            <input
              type="text"
              name="firstName"
              value={firstName}
              className="form-control"
              placeholder="First Name"
              onChange={this.handleOnChange}
            />
          </div>
          <div className="form-group col-md-6">
            <input
              type="text"
              name="lastName"
              value={lastName}
              className="form-control"
              placeholder="Last Name"
              onChange={this.handleOnChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <input
              type="text"
              value={companyName}
              name="companyName"
              className="form-control"
              placeholder="Company"
              onChange={this.handleOnChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <Select
              options={options}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#2b3553",
                  primary: "#c61f91"
                }
              })}
              defaultInputValue={industryCategory}
              onChange={({ value }) =>
                this.setState({ industryCategory: value })
              }
            />
          </div>
          <div className="form-group col-md-6">
            <input
              type="text"
              name="departmentName"
              className="form-control"
              placeholder="Department"
              onChange={this.handleOnChange}
            />
          </div>
        </div>
        {this.checkIndustry() ? (
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Industry Category</label>
              <input
                type="text"
                name="otherIndustryCategory"
                className="form-control"
                placeholder="Industry Category"
                onChange={this.handleOnChange}
              />
            </div>
          </div>
        ) : null}
        <div className="form-row">
          <div className="form-group col-md-12">
            <input
              type="text"
              name="jobTitle"
              className="form-control"
              placeholder="Job Title"
              onChange={this.handleOnChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <input
              type="text"
              name="location"
              className="form-control"
              placeholder="Location"
              onChange={this.handleOnChange}
            />
          </div>
        </div>
      </form>
    ) : (
      <form>
        <div className="form-group">
          <input
            type="email"
            name="workemail"
            className="form-control"
            placeholder="Work Email"
            onChange={this.handleOnChange}
          />
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              name="password"
              value={password}
              type={pass01type ? "password" : "text"}
              className="form-control"
              placeholder="Password"
              onChange={this.handleOnChange}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <img
                  onClick={() => this.showPassword("pass01type")}
                  className="Show-password"
                  src="assets/show-password.png"
                  alt=""
                  srcSet="assets/show-password@2x.png 2x,
                  assets/show-password@3x.png 3x"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              name="confirmpassword"
              value={confirmpassword}
              type={pass02type ? "password" : "text"}
              className="form-control"
              placeholder="Confirm Password"
              onChange={this.handleOnChange}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <img
                  onClick={() => this.showPassword("pass02type")}
                  className="Show-password"
                  src="assets/show-password.png"
                  alt=""
                  srcSet="assets/show-password@2x.png 2x,
                  assets/show-password@3x.png 3x"
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  renderError = () => {
    let { errorMessage } = this.state;
    return errorMessage ? (
      <div className="alert alert-danger alert-with-icon">
        <button
          type="button"
          aria-hidden="true"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <i className="tim-icons icon-simple-remove"></i>
        </button>
        <span>
          <b> Error! - </b> {errorMessage}
        </span>
      </div>
    ) : null;
  };

  render() {
    let { nexstep, loading } = this.state;
    return (
      <Fragment>
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-6">
              {this.info()}
              <div className="row">
                <div className="col-md-12">
                  <div className={TopMargin}></div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-sm-12">{this.registered()}</div>
                <div className="col-md-2 col-sm-12">
                  <button
                    data-toggle="modal"
                    data-target="#passwordPolicy"
                    className={Info}
                  >
                    <img src="assets/info.svg" />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-4">
                  <button
                    id="btnSignup"
                    type="submit"
                    className={Signup}
                    onClick={() => {
                      nexstep
                        ? this.updateTheUser(() => {
                            this.setState({ loading: false });
                          })
                        : this.cognitoRegisterUser();
                    }}
                  >
                    {nexstep ? "Save" : "Register"}{" "}
                    {loading ? (
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <img
                        className={Arrow}
                        src="assets/arrow-right.svg"
                        alt=""
                      />
                    )}
                  </button>
                </div>
                <div className="col-md-6 col-sm-8"></div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className={TopMargin}></div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <span className={Dont_have_an_account}>
                    Already have an account ? {"  "}
                    <Link className={Signin} to="/login">
                      Login
                    </Link>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">{this.renderError()}</div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="assets/Signup-1_HD.png" className={Demo_2} />
            </div>
          </div>
          <div className="row"></div>
        </div>

        <div
          className={`${ModelBackground} modal fade bd-example-modal`}
          id="passwordPolicy"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="passwordPolicy"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className={`${Model} modal-content`}>
              <div className="modal-header">
                <h3
                  className={`${ModelHeader} modal-title`}
                  id="passwordPolicy"
                >
                  Password Policy
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/*<p className={ModelText}>
                  1) Your password must be between 8 and 30 characters.<br></br>
                  2) Your password must contain at least one uppercase, or
                  capital, letter. <br></br>
                  3) Your password must contain at least one lowercase letter.
                  <br></br>
                  4) Your password must contain at least one number digit (ex:
                  0, 1, 2, 3, etc.). <br></br>
                  5) Your password must contain at least one special character
                  -for example: $, #, @, !,%,^,&,*,(,).<br></br>
                </p>*/}
                <p className={ModelText}>
                  Your password must be between 6 and 30 characters.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    updateSuccess: state.user.updateSuccess,
    loading: state.user.loading
  };
};

let mapDispatchToProps = dispatch => {
  return {
    updateUser: (data, cb) => {
      dispatch(UpdateUser(data));
      cb();
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(withRouter(Register)));
