import React from "react";
import {
  Arrow,
  StartAsses,
  StartCard,
  AssesLogo,
  TakeAssesText,
  StartWrapper,
  StartCardSquar,
  StartAssesButton
} from "./AssesmentStart.module.scss";

function AssesmentStart({ type, startAssesment }) {
  return type === "home" ? (
    <div className={StartCardSquar}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ margin: "15px" }} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <img className={AssesLogo} src="assets/assessment.svg" />
          </div>
          <div className="col-md-7"></div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <span className={TakeAssesText}>
              Take your first assessment to see the progress
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-4">
            <div style={{ margin: "15px" }} />
          </div>
          <div className="col-md-12 col-sm-8">
            <button
              className={StartAssesButton}
              style={{ float: "right", marginBottom: "7px" }}
              onClick={startAssesment}
            >
              <p className={StartWrapper}>
                Start assessment
                <img className={Arrow} src="assets/arrow.svg" />
              </p>{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={StartCard}>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-3">
            <img className={AssesLogo} src="assets/assessment.svg" />
          </div>
          <div className="col-md-7 col-sm-9">
            <div className="row">
              <div className="col-md-12">
                <div style={{ margin: "15px" }} />
              </div>
              <div className="col-md-12">
                <span className={TakeAssesText}>
                  Start a new assessment or continue a draft assessment
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div style={{ margin: "15px" }} />
              </div>
              <div className="col-md-12">
                <button className={StartAssesButton} onClick={startAssesment}>
                  <p className={StartWrapper}>
                    Start assessment
                    <img className={Arrow} src="assets/arrow.svg" />
                  </p>{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssesmentStart;
