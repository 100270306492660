import React from "react";
import { progress, remain } from "./Chart.module.scss";

const getColor = (status, percentage) => {
  if (status === "DRAFT") {
    return "#ffffff";
  }
  return percentage > 90 ? "#7cdb85" : "#ffb51a";
};

const getValue = percentage => 142 - (percentage * 142) / 100;

function Chart({ percentage, status }) {
  return (
    <svg viewBox="-20 0 140 60">
      <g fillOpacity="0" strokeWidth="8">
        <path className={remain} d="M5 50a45 45 0 1 1 90 0" />
        <path
          className={progress}
          d="M5 50a45 45 0 1 1 90 0"
          stroke={getColor(status, percentage)}
          strokeDasharray="142"
          strokeDashoffset={getValue(percentage)}
        />
      </g>
      <text
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="20"
        fontFamily="Poppins"
        x="50"
        y="40"
        fill="#ffffff"
        fontWeight="bold"
      >
        {percentage}%
      </text>
    </svg>
  );
}

export default Chart;
