export const LIST_ASSESMENTS = "LIST_ASSESMENTS";
export const CREATE_ASSESMENT = "CREATE_ASSESMENT";
export const GET_ASSESMENT = "GET_ASSESMENT";
export const SELECT_ASSIGNMENT = "SELECT_ASSIGNMENT";
export const UPDATE_ASSESMENT = "UPDATE_ASSESMENT";
export const UPDATE_ASSESMENT_PAYMENT = "UPDATE_ASSESMENT_PAYMENT";
export const GET_ASSESMENT_QUESTION = "GET_ASSESMENT_QUESTION";
export const UPDATE_ASSESMENT_QUESTION = "UPDATE_ASSESMENT_QUESTION";
export const ERROR_ASSESMENT = "ERROR_ASSESMENT";
export const GET_MOREINFO = "GET_MOREINFO";
export const CLEAR_MOREINFO = "CLEAR_MOREINFO";
export const LOADING = "LOADING";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const STRIPE_FAIL = "STRIPE_FAIL";
export const CLEAR_STRIPE_STATUS = "CLEAR_STRIPE_STATUS";
