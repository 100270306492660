import axios from "axios";
import { getIdToken } from "../Util/Auth";
import {
  LIST_QUESTIONS,
  ERROR_QUESTION,
  LOADING
} from "../Constants/Questions";

let baseUrl = process.env.REACT_APP_BASE_URL;

export const ListAllQuestions = () => {
  return async dispatch => {
    return axios
      .get(`${baseUrl}/questions`, {
        headers: { Authorization: await getIdToken() }
      })
      .then(data => dispatch({ type: LIST_QUESTIONS, payload: data }))
      .catch(error => dispatch({ type: ERROR_QUESTION, payload: "" }));
  };
};
