import React from "react";
import Home from "./Components/Home";
import Public from "./Components/Public";
import Login from "./Components/Login";
import Register from "./Components/Register";
import AssesmentCard from "./Components/AssesmentCard";
import Navigation from "./Components/Navigation";
import Chart from "./Components/Chart";
import Account from "./Components/Account";
import NotFound from "./Components/NotFound";
import Assesment from "./Components/Assesment";
import Reset from "./Components/Reset";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import "./App.scss";
import { Provider } from "react-redux";
import configureStore from "./Store/Store";
import { saveState } from "./Util/Persist";
import { isAuthenticated } from "./Util/Auth";
import { ToastProvider } from "react-toast-notifications";

const store = configureStore();

store.subscribe(() => {
  saveState({
    category: store.getState().category,
    assesment: store.getState().assesment,
    user: store.getState().user
  });
});

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={props => <PublicRoute component={Public} {...props} />}
            />
            <Route
              exact
              path="/register"
              render={props => <PublicRoute component={Register} {...props} />}
            />
            <Route
              exact
              path="/home"
              render={props => <PrivateRoute component={Home} {...props} />}
            />
            <Route
              exact
              path="/assesment"
              render={props => (
                <PrivateRoute component={Assesment} {...props} />
              )}
            />
            <Route
              exact
              path="/report"
              render={props => (
                <PrivateRoute component={Assesment} type="report" {...props} />
              )}
            />
            <Route
              exact
              path="/account"
              render={props => <PrivateRoute component={Account} {...props} />}
            />
            <Route
              exact
              path="/login"
              render={props => <PublicRoute component={Login} {...props} />}
            />
            <Route
              exact
              path="/reset"
              render={props => <PublicRoute component={Reset} {...props} />}
            />
            <Route path="*" render={props => <NotFound {...props} />} />
          </Switch>
        </Router>
      </ToastProvider>
    </Provider>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Navigation>
          <Component {...props} />
        </Navigation>
      ) : (
        <Redirect
          to={{
            pathname: "/login"
          }}
        />
      )
    }
  />
);
const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Redirect to="/home" />
      ) : (
        <Navigation type="public">
          <Component {...props} />
        </Navigation>
      )
    }
  />
);

export default App;
