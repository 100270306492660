import React from "react";
import { Modalli, Header } from "./Modal.module.scss";

function PrivacyPolicy() {
  return (
    <div>
      <h3 className={Header}>Effective January 22, 2020</h3>
      <p>
        Protecting your private information is our priority. This privacy policy
        governs your use of the AIRR software application (“Application”) on a
        PC or mobile device. The Application was created by InCite Logix LLC,
        and it includes an artificial intelligence readiness assessment and
        prescriptive roadmap. You will need to review and accept the terms of
        this privacy policy before this Application may be downloaded. We want
        you to know what data of yours will be collected and how that data will
        be used.
      </p>
      <h3 className={Header}>POLICY</h3>
      <p>
        Protecting your private information is our priority. This privacy policy
        governs your use of the AIRR software application (“Application”) on a
        PC or mobile device. The Application was created by InCite Logix LLC.
        Here is what you should know.
      </p>

      <h3 className={Header}>
        What Information Does the Application Obtain and How is it Used?
      </h3>

      <p>
        <strong>User Provided Information</strong> – The Application obtains the
        information you provide when you download and register the Application.
        Registration with us is mandatory for use of the basic features of the
        Application.
      </p>
      <p>
        When you register with us and use the Application, you generally
        provide: (a) your name, email address, age, user name, password and
        other registration information; (b) transaction-related information,
        such as when you use the Application; (c) credit card information for
        purchase and use of the Application; (d) information about your company,
        such as company name, type of business, number of employees, business
        locations and other company information; (e) other information you enter
        into our system when using the Application, such as answers to the
        questions generated by the Application.
      </p>
      <p>
        <strong>Automatically Collected Information</strong> - In addition, the
        Application may collect certain information automatically, such as: the
        type of operating system and/or mobile device you use, your mobile
        device’s unique device ID, the IP address of your PC or mobile device,
        the type of Internet browsers you use, and information about the way you
        use the Application.
      </p>
      <h3 className={Header}>What Do We Do with the Information We Gather?</h3>
      <p>
        We use your information to provide you with an artificial intelligence
        readiness assessment and prescriptive analytics based on the assessment.
        We may use the information to contact you from time to time to provide
        you with important information, required notices, information about
        other products and services, and to complete your payment and
        transactions.
      </p>
      <p>
        We also collect and compile data and information related to your use of
        the Application and use it in an aggregate and anonymized manner,
        including to compile statistical and performance information related to
        the operation of the Application and to form the basis of benchmarking
        against other user organizations (the “Aggregated Data”). We may (a)
        make Aggregated Data publicly available in compliance with applicable
        law; and (b) use Aggregated Data to the extent and in the manner
        permitted under applicable law.
      </p>
      <h3 className={Header}>
        Does the Application Collect Precise Real Time Location Information of
        the Device?
      </h3>
      <p>
        This Application does not collect precise information about the location
        of your laptop or mobile device.
      </p>
      <h3 className={Header}>
        Do Third Parties See and/or Have Access to Information Obtained by the
        Application?
      </h3>
      <p>
        Yes. We will share your information with third parties only in the ways
        that are described in this privacy statement.
      </p>
      <p>
        We may disclose User Provided and Automatically Collected Information.
      </p>
      <div>
        <ul>
          <li className={Modalli}>
            <strong>1</strong>. as required by law, such as to comply with a
            subpoena, or similar legal process.
          </li>
          <li className={Modalli}>
            <strong>2</strong>. when we believe in good faith that disclosure is
            necessary to protect our rights, protect your safety or the safety
            of others, investigate fraud, or respond to a government request.
          </li>
          <li className={Modalli}>
            <strong>3</strong>. to our trusted service providers who work on our
            behalf, do not have an independent use of the information we
            disclose to them, and have agreed to adhere to the rules set forth
            in this privacy statement.
          </li>
          <li className={Modalli}>
            <strong>3</strong>. to entities affiliated by common ownership to
            InCite Logix LLCs.
          </li>
          <li className={Modalli}>
            <strong>4</strong>. if InCite Logix LLC is involved in a merger,
            acquisition, or sale of all or a portion of its assets, we may share
            your information with a party to the transaction and you will be
            notified via email and/or a prominent notice on our Web site of any
            change in ownership or uses of this information, as well as any
            choices you may have regarding this information.
          </li>
        </ul>
      </div>
      <h3 className={Header}>What Are My Opt-out Rights?</h3>
      <p>
        You can easily stop all collection of information by the Application by
        uninstalling the Application. You may use the standard uninstall
        processes that are available as part of your PC or mobile device or via
        the mobile application marketplace or network.{" "}
      </p>
      <h3 className={Header}>
        Data Retention Policy, Managing Your Information
      </h3>
      <p>
        We will retain User Provided data for as long as you use the Application
        and for a reasonable time thereafter and will retain non-identifying
        Aggregated Data indefinitely. If you would like us to delete User
        Provided Data that you have provided via the Application, please contact
        us at Support@InCiteLogix.com and we will respond in a reasonable time.
        Rather than delete your data, we might de-identify it by removing
        identifying details. Please note that some or all of the User Provided
        Data may be required in order for the Application to function properly,
        and we may be required by law to retain certain information.
      </p>
      <h3 className={Header}>“Do Not Track” Signals</h3>
      <p>
        The Application does not support “Do Not Track” browser settings and we
        do not change our practices in response to a “Do Not Track” signal in
        the HTTP header from a browser or mobile application.
      </p>
      <h3 className={Header}>Children</h3>
      <p>
        We do not use the Application to knowingly solicit data from or market
        to children under the age of 13. If a parent or guardian becomes aware
        that his or her child has provided us with information without their
        consent, he or she should contact us at Support@InCiteLogix.com. We will
        delete such information from our files within a reasonable time.
      </p>
      <h3 className={Header}>Security</h3>
      <p>
        We are concerned about safeguarding the confidentiality of your
        information. We provide physical, electronic, and procedural safeguards
        to protect information once it is received from you. For example, we
        limit access to this information to authorized employees, service
        providers, and contractors who need to know that information in order to
        operate, develop or improve our Application. You are responsible for the
        security on your PC or mobile device and actions you take may affect
        security. Please be aware that, although we endeavor to provide
        reasonable security for information we process and maintain, no security
        system prevents all potential security breaches.
      </p>
      <h3 className={Header}>Changes</h3>
      <p>
        This Privacy Policy may be updated from time to time for any reason. We
        will notify you of any changes to our Privacy Policy by posting the new
        Privacy Policy here: LINK. You are advised to consult this Privacy
        Policy regularly for any changes, as continued use is deemed approval of
        all changes.
      </p>
      <h3 className={Header}>Your Consent</h3>
      <p>
        By using the Application, you are consenting to our processing of User
        Provided and Automatically Collected information as set forth in this
        Privacy Policy now and as amended by us. "Processing," means using
        cookies on a computer/hand-held device or using or touching information
        in any way, such as collecting, storing, deleting, using, combining and
        disclosing information
      </p>
      <h3 className={Header}>Contact us </h3>
      <p>
        If you have any questions regarding our privacy policies while using the
        Application, please contact us at&nbsp;
        <a href="mailto:support@incitelogix.com">
          <strong>Support@InCiteLogix.com</strong>
        </a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
