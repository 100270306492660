import React from "react";
import { Arrow, Active } from "./Pagination.module.scss";
import {
  createUltimatePagination,
  ITEM_TYPES
} from "react-ultimate-pagination";

const WrapperComponent = ({ children }) => (
  <ul className="pagination" style={{ marginLeft: "10px", marginTop: "5px" }}>
    {children}
  </ul>
);

const withPreventDefault = fn => event => {
  event.preventDefault();
  fn();
};

const Page = ({ value, isActive, onClick }) => (
  <li className={isActive ? `page-item active` : "page-item"}>
    <a className="page-link" href="/#" onClick={withPreventDefault(onClick)}>
      {value}
    </a>
  </li>
);

const Ellipsis = ({ onClick }) => (
  <li className="page-item">
    <a className="page-link" href="/#" onClick={withPreventDefault(onClick)}>
      ...
    </a>
  </li>
);

const FirstPageLink = ({ onClick, progress }) => (
  <li className="page-item">
    <a className="page-link" href="/#" onClick={withPreventDefault(onClick)}>
      <img src="assets/double-arrow-back.svg" className={Arrow} />
    </a>
  </li>
);
const LastPageLink = ({ onClick, progress }) => (
  <li className="page-item">
    <a className="page-link" href="/#" onClick={withPreventDefault(onClick)}>
      <img src="assets/double-arrow-next.svg" className={Arrow} />
    </a>
  </li>
);
const PreviousPageLink = ({ onClick }) => (
  <li className="page-item">
    <a className="page-link" href="/#" onClick={withPreventDefault(onClick)}>
      &lsaquo;
    </a>
  </li>
);

const NextPageLink = ({ onClick }) => (
  <li className="page-item">
    <a className="page-link" href="/#" onClick={withPreventDefault(onClick)}>
      &rsaquo;
    </a>
  </li>
);

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink
};

const Pagination = createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent
});

export default Pagination;
