import axios from "axios";
import { getIdToken } from "../Util/Auth";
import {
  LIST_CATEGORIES,
  ERROR_CATEGORY,
  LOADING
} from "../Constants/Categories";

let baseUrl = process.env.REACT_APP_BASE_URL;

export const ListAllCategories = () => {
  return async dispatch => {
    return axios
      .get(`${baseUrl}/categories`, {
        headers: { Authorization: await getIdToken() }
      })
      .then(data => dispatch({ type: LIST_CATEGORIES, payload: data.data }))
      .catch(error => dispatch({ type: ERROR_CATEGORY, payload: error }));
  };
};
