import { LIST_QUESTIONS, LOADING } from "../Constants/Questions";

const initialState = { questions: [], loading: false };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_QUESTIONS:
      return { ...state, questions: payload };
    case LOADING:
      return { ...state, loading: payload };

    default:
      return state;
  }
};
