import React, { Component, Fragment } from "react";
import {
  Tick,
  Combinedshape,
  Oval,
  Nopaddingright,
  Nopaddingleft
} from "./Checkbox.module.scss";

class Checkbox extends Component {
  state = {
    ticked: false
  };
  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-2">
            <img src="assets/tick.svg" className={Tick} />
            <img className={Combinedshape} src="assets/step-connection.svg" />
            <div className={Oval}></div>
          </div>
          <div className="col-md-10 col-sm-10">
            <p style={{ marginTop: "7px" }}>Register on platform.</p>
            <br />
            <p style={{ marginTop: "7px" }}>Complete first assessment.</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Checkbox;
