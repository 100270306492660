import { combineReducers } from "redux";
import UsersReducer from "./UsersReducer";
import QuestionsReducer from "./QuestionsReducer";
import CategoriesReducer from "./CategoriesReducer";
import AssesmentsReducer from "./AssesmentsReducer";

export default combineReducers({
  user: UsersReducer,
  question: QuestionsReducer,
  category: CategoriesReducer,
  assesment: AssesmentsReducer
});
