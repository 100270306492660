import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import {
  GetUser,
  UpdateUser,
  UpdateNotification
} from "../Actions/UsersActions";
import { ResendVerifyEmail } from "../Actions/AssesmentActions";
import {
  UserAccount,
  FormSection,
  AccountImage,
  AccountContainer,
  EmailVerify,
  Resend,
  FormLabel
} from "./Account.module.scss";
import { isEmailVerified } from "../Util/Auth";
import { withToastManager } from "react-toast-notifications";

const options = [
  { label: "Petrochemical/Refining", value: "Petrochemical/Refining" },
  { label: "Chemical Processing", value: "Chemical Processing" },
  {
    label: "Storage/Pipeline/Transportation",
    value: "Storage/Pipeline/Transportation"
  },
  {
    label: "Oil & Gas Exploration/Production",
    value: "Oil & Gas Exploration/Production"
  },
  {
    label: "Power Generation/Utilities",
    value: "Power Generation/Utilities"
  },
  { label: "Discreet Manufacturing", value: "Discreet Manufacturing" },
  { label: "Food/Pharmaceuticaln", value: "Food/Pharmaceuticaln" },
  {
    label: "Industrial Services/Products",
    value: "Industrial Services/Products"
  },
  {
    label: "Other (Specify industry in the field below)",
    value: "Other (Specify industry in the field below)"
  }
];

const customStyles = {
  control: (base, state) => ({ ...base, height: "50px", "min-height": "50px" })
};

class Account extends Component {
  state = {
    editing: false,
    firstName: "",
    lastName: "",
    departmentName: "",
    companyName: "",
    location: "",
    industryCategory: "",
    jobTitle: ""
  };

  checkIndustry = () =>
    _.isEqual(
      this.state.industryCategory,
      "Other (Specify industry in the field below)"
    );

  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  componentDidMount() {
    this.props.getUser();
  }

  handleOnChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value, errorMessage: "" });

  editing = () => {
    this.setState((prevState, preProps) => {
      return { editing: !prevState.editing };
    });
  };

  updateUserData = () => {
    let {
      user: {
        firstName,
        lastName,
        departmentName,
        companyName,
        location,
        industryCategory,
        jobTitle
      }
    } = this.props;

    this.props.updateUser({
      firstName: _.get(this.state, "firstName") || firstName,
      lastName: _.get(this.state, "lastName") || lastName,
      departmentName: _.get(this.state, "departmentName") || departmentName,
      companyName: _.get(this.state, "companyName") || companyName,
      location: _.get(this.state, "location") || location,
      industryCategory: this.checkIndustry()
        ? _.get(this.state, "industryCategoryOther")
        : _.get(this.state, "industryCategory") || industryCategory,
      jobTitle: _.get(this.state, "jobTitle") || jobTitle
    });
  };

  checkEmailVerify = () => {
    if (isEmailVerified()) {
      return true;
    } else {
      return _.get(this.props, "emailsent", false);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.updateSuccess !== this.props.updateSuccess &&
      this.props.updateSuccess
    ) {
      this.props.toastManager.add(
        "User details updated!",
        {
          appearance: "success",
          autoDismiss: true
        },
        () => {
          this.props.updateNotification();
        }
      );
    }
  }

  render() {
    let { editing } = this.state;
    let { user } = this.props;

    return (
      <div className={`${AccountContainer} container`}>
        {this.checkEmailVerify() ? null : (
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div class="alert alert-warning" role="alert">
                <p className={EmailVerify}>
                  Please verify you email! We have sent an email with a
                  confirmation link to your email address.{" "}
                  <b className={Resend} onClick={this.props.resendVerifyEmail}>
                    Click here to resend
                  </b>
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <span className={UserAccount}>User Account</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div style={{ margin: "25px" }}></div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className={FormSection}>
              <div className="row">
                <div className="col-md-5">
                  <picture>
                    <source
                      className={AccountImage}
                      srcSet="assets/User-Accounts-_HD.webp"
                      type="image/webp"
                    />
                    <source
                      className={AccountImage}
                      srcSet="assets/User-Accounts-_HD.png"
                      type="image/png"
                    />
                    <img
                      className={AccountImage}
                      src="assets/User-Accounts-_HD.png"
                    />
                  </picture>
                </div>
                <div className="col-md-7">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className="col-md-12">
                          <div style={{ margin: "25px" }}></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <form>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className={FormLabel}>First Name</label>
                              <input
                                type="text"
                                name="firstName"
                                defaultValue={_.get(user, "firstName", "")}
                                disabled={!editing}
                                className="form-control"
                                placeholder="First Name"
                                onChange={this.handleOnChange}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label className={FormLabel}>Last Name</label>
                              <input
                                type="text"
                                name="lastName"
                                defaultValue={_.get(user, "lastName", "")}
                                disabled={!editing}
                                className="form-control"
                                placeholder="Last Name"
                                onChange={this.handleOnChange}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className={FormLabel}>Organization</label>
                              <input
                                type="text"
                                name="companyName"
                                defaultValue={_.get(user, "companyName", "")}
                                disabled={!editing}
                                className="form-control"
                                placeholder="Comapany"
                                onChange={this.handleOnChange}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <div className="dropdown">
                                <label className={FormLabel}>
                                  Industry Category
                                </label>
                                <Select
                                  // styles={customStyles}
                                  isDisabled={!editing}
                                  defaultInputValue={_.get(
                                    user,
                                    "industryCategory",
                                    ""
                                  )}
                                  options={options}
                                  theme={theme => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#2b3553",
                                      primary: "#c61f91"
                                    }
                                  })}
                                  value={this.state.value}
                                  onChange={({ value }) =>
                                    this.setState({ industryCategory: value })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <label className={FormLabel}>Department</label>
                              <input
                                type="text"
                                name="departmentName"
                                defaultValue={_.get(user, "departmentName", "")}
                                disabled={!editing}
                                className="form-control"
                                placeholder="Department"
                                onChange={this.handleOnChange}
                              />
                            </div>
                          </div>
                          {this.checkIndustry() ? (
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <label>Industry Category</label>
                                <input
                                  type="text"
                                  name="industryCategoryOther"
                                  defaultValue={_.get(
                                    user,
                                    "industryCategory",
                                    ""
                                  )}
                                  disabled={!editing}
                                  className="form-control"
                                  placeholder="Industry Category"
                                  onChange={this.handleOnChange}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className={FormLabel}>Job Title</label>
                              <input
                                type="text"
                                disabled={!editing}
                                name="jobTitle"
                                defaultValue={_.get(user, "jobTitle", "")}
                                className="form-control"
                                placeholder="Job Title"
                                onChange={this.handleOnChange}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className={FormLabel}>Location</label>
                              <input
                                type="text"
                                defaultValue={_.get(user, "", "location")}
                                disabled={!editing}
                                name="location"
                                className="form-control"
                                placeholder="Location"
                                onChange={this.handleOnChange}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-8"></div>
                            <div className="form-group col-md-4">
                              <button
                                className="btn btn-primary"
                                style={{ float: "right" }}
                                onClick={e => {
                                  e.preventDefault();
                                  if (editing) {
                                    this.updateUserData();
                                  } else {
                                    this.setState({ editing: true });
                                  }
                                }}
                              >
                                {!editing ? "Edit" : "Update"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    user: state.user.user,
    emailsent: state.assesment.emailsent,
    updateSuccess: state.user.updateSuccess
  };
};

let mapDispatchToProps = dispatch => {
  return {
    getUser: () => {
      dispatch(GetUser());
    },
    updateUser: data => {
      dispatch(UpdateUser(data));
    },
    resendVerifyEmail: () => {
      dispatch(ResendVerifyEmail());
    },
    updateNotification: () => {
      dispatch(UpdateNotification());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withToastManager(Account)));
