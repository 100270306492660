import React, { Component } from "react";
import {
  AssementCard,
  AssementResultCard,
  Text,
  Date,
  Line,
  Ready,
  Complete,
  Read_Text,
  Category,
  CategoryRow,
  CategoryQuestions
} from "./AssesmentCard.module.scss";
import moment from "moment";
import GuageChart from "./GuageChart";
import Chart from "./Chart";

export default class AssesmentCard extends Component {
  getCardColor = status =>
    status === "DRAFT"
      ? "linear-gradient(to top, #fe5196, #f77062)"
      : " linear-gradient(to bottom, #22e1ff, #1d8fe1, #255696)";

  getCardText = (total, status) => {
    if (status === "DRAFT") {
      return "You have work to do and you should not spend your money on AI projects yet.";
    }
    return total > 90
      ? "You are AI ready and you may spend money on AI Projects."
      : "You have work to do and you should not spend your money on AI projects yet.";
  };

  getSideText = (total, status) => {
    if (status === "DRAFT") {
      return <div className={Ready} />;
    }
    return total > 90 ? (
      <div className={Ready} style={{ backgroundColor: "#007168" }}>
        <span className={Read_Text}>AI Ready</span>
      </div>
    ) : (
      <div className={Ready} style={{ backgroundColor: "#feb319" }}>
        <span className={Read_Text}>Work to do</span>
      </div>
    );
  };

  getCategory = categories => {
    return (
      categories &&
      categories.map(category => {
        return (
          <div className="col-md-12" key={category.categoryId}>
            <span className={Category}>{category.categoryName}</span>
            <span className={CategoryQuestions}>{category.score}</span>
          </div>
        );
      })
    );
  };

  getBadge = status =>
    status === "COMPLETED" ? (
      <img className={Complete} src="assets/test-passed.svg" />
    ) : (
      <div className={Complete} />
    );
  render() {
    let {
      id,
      status,
      total,
      type,
      categories,
      createdOn,
      getReport,
      getAssesment,
      lastCategoryId,
      lastQuestionId,
      paymentStatus
    } = this.props;

    if (type === "result") {
      return (
        <div
          className={AssementResultCard}
          style={{ backgroundImage: this.getCardColor(status) }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-12">
                <div style={{ margin: "25px" }}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">{this.getBadge(status)}</div>
              <div className="col-md-4" />
              <div className="col-md-4">{this.getSideText(total, status)}</div>
              <div className="col-md-12">
                <Chart percentage={total} status={status} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <span className={Text}>{this.getCardText(total, status)}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div style={{ margin: "10px" }}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className={Line}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <span className={Date}>
                  {moment(createdOn).format("MMMM Do YYYY, h:mm a")}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div style={{ margin: "28px" }}></div>
              </div>
            </div>
            <div className="row">{this.getCategory(categories)}</div>
            <div className="row">
              <div className="col-md-12">
                <div style={{ margin: "14px" }}></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (type === "more") {
      return <div>asas</div>;
    } else {
      return (
        <div
          onClick={() => {
            if (status === "COMPLETED") {
              getReport(id);
            } else {
              getAssesment({
                assessmentId: id,
                lastCategoryId: lastCategoryId,
                lastQuestionId: lastQuestionId,
                paymentStatus: paymentStatus,
                status: status,
                type: "draft"
              });
            }
          }}
          className={AssementCard}
          style={{ backgroundImage: this.getCardColor(status) }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div style={{ margin: "25px" }}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">{this.getBadge(status)}</div>
              <div className="col-md-4" />
              <div className="col-md-4">{this.getSideText(total, status)}</div>
              <div className="col-md-12">
                <Chart percentage={total} status={status} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className={Text}>{this.getCardText(total, status)}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div style={{ margin: "10px" }}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className={Line}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <span className={Date}>
                  {moment(createdOn).format("MMMM Do YYYY, h:mm a")}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

AssesmentCard.defaultProps = {
  type: "assesments",
  total: 0
};
