import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class GuageChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          offsetY: -20
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              shadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: true,
                color: "#000000",
                fontSize: "13px"
              },
              value: {
                offsetY: -40,
                fontSize: "40px",
                color: "#FBBD08",
                formatter: function(val, opts) {
                  return val;
                }
              }
            }
          }
        },
        fill: {
          type: "solid",
          colors: ["#FFDA83"]
        },
        labels: [props.total >= 90 ? "AI Ready" : "You have work to do"],
        states: {
          hover: {
            filter: {
              type: "none"
            }
          }
        }
      },
      series: [props.total ? props.total : 0]
    };
  }
  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height="350"
        />
      </div>
    );
  }
}

export default GuageChart;
