import { LIST_CATEGORIES, LOADING } from "../Constants/Categories";

const initialState = {
  categories: [],
  loading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LIST_CATEGORIES:
      return { ...state, categories: payload };
    case LOADING:
      return { ...state, loading: payload };

    default:
      return state;
  }
};
