import React, { Component, Fragment } from "react";
import AssesmentTab from "./Assesment/AssesmentTab";
import Payment from "./Assesment/Payment";
import QnA from "./Assesment/QnA";
import Result from "./Assesment/Result";
import { HeaderText } from "./Assesment.module.scss";
import {
  ListAllAssesment,
  CreateAssesment,
  GetAssesment,
  GetAssesmentQuestion,
  UpdateAssesment,
  UpdateAssesmentQuestion,
  UpdateAssesmentPayment,
  GetMoreInfo,
  ClearStripe
} from "../Actions/AssesmentActions";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import CreditCard from "./Assesment/CreditCard";
import { withRouter } from "react-router-dom";
import { ListAllCategories } from "../Actions/CategoriesActions";
import { ListAllQuestions } from "../Actions/QuestionsActions";
import { withToastManager } from "react-toast-notifications";

class Assesment extends Component {
  state = {
    type: "payment",
    payment: false,
    qna: false,
    result: false,
    loaded: false,
    activeCategory: 1,
    activePage: 1,
    activeCategoryName: "",
    modalOpen: false,
    state: "assesment",
    mode: "new",
    skipedQuestions: []
  };

  routeTo = (route, state) => {
    this.props.history.push(`/${route}`, state);
  };

  onStepClick = type => {
    this.setState({
      type: type
    });
  };

  completeAssesment = (status, cb) => {
    this.props.updateAssesment({
      assessmentId: this.props.assessmentId,
      status: _.isNull(status) ? "COMPLETED" : status,
      [_.isNull(status) ? "completedOn" : "updatedOn"]: moment().format()
    });
    this.onStepClick("result");
    cb();
  };

  checkDraft = params => {
    this.props.getAssesment({
      assessmentId: params.assessmentId
    });
    if (params) {
      if (_.isEqual(_.get(params, "paymentStatus"), "SUCCESS")) {
        this.onStepClick("qa");
      } else {
        this.onStepClick("payment");
      }
      this.props.getAssesmentQuestion({
        assessmentId: params.assessmentId
      });
    }
  };

  getDraftPendingCategoryQuestion = (
    AssesQuestions,
    categoryIndex,
    skipedQuestions
  ) => {
    let pendingQuestions = AssesQuestions.filter(question =>
      _.isUndefined(question.answer)
    );
    let pendingQuestion = _.head(pendingQuestions);

    if (pendingQuestion) {
      let categoryName = _.find(_.get(this.props, "categorySort"), {
        categorySortId: pendingQuestion.categorySortId
      }).categoryName;

      this.setState({
        activePage: pendingQuestion.questionNumber,
        activeCategory: pendingQuestion.categorySortId,
        activeCategoryName: categoryName,
        pendingQuestions: pendingQuestions,
        mode: "draft",
        skipedQuestions: skipedQuestions
      });
    } else if (_.get(this.props, "progress") === 100) {
      this.setState({
        activePage: 100,
        activeCategory: 6,
        activeCategoryName: "Security",
        pendingQuestions: [],
        mode: "new",
        skipedQuestions: []
      });
    }
  };

  setDraftCategoryQuestion = (lastCategoryId, lastQuestionId) => {
    let AssesQuestions = _.get(this.props, "assesQuestions");

    let category = AssesQuestions.find(
      category =>
        category.categoryId === lastCategoryId &&
        category.questionId === lastQuestionId
    );

    let categoryIndex = AssesQuestions.findIndex(
      category =>
        category.categoryId === lastCategoryId &&
        category.questionId === lastQuestionId
    );

    this.setState(
      prevState => {
        if (prevState.activeCategory !== category.categorySortId) {
          let categoryName = _.find(_.get(this.props, "categorySort"), {
            categorySortId: category.categorySortId
          }).categoryName;

          return {
            activeCategory: category.categorySortId,
            activeCategoryName: categoryName
          };
        } else if (prevState.activePage !== category.questionNumber) {
          let categoryName = _.find(_.get(this.props, "categorySort"), {
            categorySortId: category.categorySortId
          }).categoryName;

          return {
            activePage: category.questionNumber + 1,
            activeCategoryName: category.categoryName,
            loaded: true
          };
        }
      },
      () => {
        let skipedQuestions = this.getSkippedQuestionFromDraft();
        this.getDraftPendingCategoryQuestion(
          AssesQuestions,
          categoryIndex,
          skipedQuestions
        );
      }
    );
  };

  componentDidMount() {
    let params = _.get(this.props, "location.state", null);
    if (_.isEqual(_.get(params, "type", "assesment"), "report")) {
      this.props.getAssesment({ assessmentId: params.assessmentId });
      this.props.getAssesmentQuestion({
        assessmentId: params.assessmentId
      });
      this.setState({ state: "report", type: "result" });
    } else if (_.isEqual(_.get(params, "type", "assesment"), "draft")) {
      this.checkDraft(params);
    } else {
    }
  }

  getQuestion = () => {
    this.props.getAssesmentQuestion({
      assessmentId: this.props.assessmentId
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLastAnswered !== this.props.isLastAnswered) {
      this.props.toastManager.add(
        this.props.isAllCompleted
          ? "Congratulations, you are done!. Click Result to see a summary of your responses."
          : "You can save this draft and come back later to finish unanswered questions by clicking the Draft and exit button",
        {
          appearance: this.props.isAllCompleted ? "success" : "warning",
          autoDismiss: true
        }
      );
    }

    if (
      this.props.stripeStatus === "FAIL" &&
      prevProps.stripeStatus !== this.props.stripeStatus
    ) {
      this.props.toastManager.add(
        _.get(
          this.props.stripeMessage,
          "data.message",
          "Payment failed please check you card number and try again"
        ),
        { appearance: "error", autoDismiss: true },
        () => {
          this.props.clearStripe();
        }
      );
    }

    if (
      this.props.stripeStatus === "SUCCESS" &&
      prevProps.stripeStatus !== this.props.stripeStatus
    ) {
      this.props.toastManager.add(
        "Payment successful",
        { appearance: "success", autoDismiss: true },
        () => {
          this.props.clearStripe();
          this.onStepClick("qa");
        }
      );
    }

    if (this.props.paystatus === "SUCCESS") {
      this.getQuestion();
    }

    let params = _.get(this.props, "location.state", null);
    if (
      params &&
      !_.isEmpty(_.get(this.props, "assesQuestions", [])) &&
      !this.state.loaded
    ) {
      if (params.lastCategoryId && params.lastQuestionId) {
        this.setDraftCategoryQuestion(
          params.lastCategoryId,
          params.lastQuestionId
        );
      }
    }
  }

  getQuestionInCategory = (assesQuestions, activeCategory, activePage) => {
    return _.find(assesQuestions, {
      categorySortId: activeCategory,
      questionNumber: activePage
    });
  };

  selectCategory = no => {
    let questionNumber = _.find(_.get(this.props, "assesQuestions"), {
      categorySortId: no
    }).questionNumber;
    this.setState({
      activeCategory: no,
      activePage: questionNumber
    });
  };

  getCategoryNameById = categoryId =>
    _.get(
      _.find(_.get(this.props, "categorySort"), { categoryId: categoryId }),
      "categoryName"
    );

  getSkippedQuestionFromDraft = () => {
    let viewedQuestions = _.slice(
      _.get(this.props, "assesQuestions"),
      0,
      _.findLastIndex(
        _.get(this.props, "assesQuestions"),
        question => !_.isUndefined(question.answer)
      )
    );

    let skippedQuestionNumbers = _.map(
      _.filter(viewedQuestions, question => _.isUndefined(question.answer)),
      question => {
        return {
          activePage: question.questionNumber,
          activeCategory: question.categorySortId,
          activeCategoryName: this.getCategoryNameById(question.categoryId)
        };
      }
    );

    return skippedQuestionNumbers;
  };

  makepaymnet = paymentToken => {
    this.props.updateAssesmentPayment(
      {
        assessmentId: this.props.assessmentId,
        paymentToken: paymentToken
      },
      () => {
        //this.onStepClick("qa");
      }
    );
  };

  getFrame = ({ type }) => {
    let { assesQuestions } = this.props;
    let { activeCategory, activePage } = this.state;
    let Questions = this.getQuestionInCategory(
      assesQuestions,
      activeCategory,
      activePage
    );
    if (type === "payment") {
      return (
        <Payment
          makepaymnet={this.makepaymnet}
          done={_.isEqual(_.get(this.props, "paymentStatus"), "SUCCESS")}
        />
      );
    } else if (type === "qa") {
      return (
        <QnA
          mode={this.state.mode}
          handleCategoryClick={this.handleCategoryClick}
          selectCategory={this.selectCategory}
          activeCategory={this.state.activeCategory}
          routeTo={this.routeTo}
          paymentStatus={_.isEqual(
            _.get(this.props, "paymentStatus"),
            "SUCCESS"
          )}
          completeAssesment={this.completeAssesment}
          onStepClick={this.onStepClick}
          categories={this.props.categorySort}
          getMoreInfo={this.props.getMoreInfo}
          moreinfo={this.props.moreinfo}
          question={Questions}
          questions={assesQuestions}
          markAnswer={this.markAnswer}
          progress={_.get(this.props, "progress")}
          getQuestionCount={this.getQuestionCount(this.props.assesQuestions)}
          handlePaginationChange={this.handlePaginationChange}
          activePage={this.state.activePage}
        />
      );
    } else {
      return (
        <Result
          categoryScores={this.props.categoryScores}
          assesQuestionsReport={_.get(this.props, "assesQuestionsReport", [])}
          totalScore={_.get(this.props, "totalScore")}
          location={_.get(this.props, "location.state.from")}
          moreinfo={this.props.moreinfo}
          getMoreInfo={this.props.getMoreInfo}
          reportType={this.state.state}
        />
      );
    }
  };

  handleCategoryClick = (e, { name }) =>
    this.setState({ activeCategoryName: name });

  markAnswer = (questionId, categoryId, assessmentId, data) => {
    let { activePage, activeCategory, skipedQuestions, mode } = this.state;
    let { assesQuestions } = this.props;
    if (!_.isEmpty(assesQuestions)) {
      this.props.updateAssesmentQuestion({
        categoryId: categoryId,
        assessmentId: assessmentId,
        questionId: questionId,
        answer: data,
        createdOn: moment().format()
      });
    }
    this.setState(
      (prevState, props) => {
        if (prevState.activePage < this.getQuestionCount(assesQuestions)) {
          if (skipedQuestions.length > 0 && mode === "draft") {
            let isLast = skipedQuestions.length === 0;
            return {
              activePage: isLast
                ? skipedQuestions[1].activePage
                : skipedQuestions[0].activePage,
              activeCategory: isLast
                ? skipedQuestions[1].activeCategory
                : skipedQuestions[0].activeCategory,
              activeCategoryName: isLast
                ? skipedQuestions[1].activeCategoryName
                : skipedQuestions[0].activeCategoryName,
              skipedQuestions: skipedQuestions.shift()
            };
          } else {
            let categorySortId = _.find(_.get(this.props, "assesQuestions"), {
              questionNumber: activePage + 1
            }).categorySortId;
            let categoryName = _.find(_.get(this.props, "categorySort"), {
              categorySortId: categorySortId
            }).categoryName;

            return {
              activePage: activePage + 1,
              activeCategory: categorySortId,
              activeCategoryName: categoryName
            };
          }
        } else if (
          prevState.activePage === this.getQuestionCount(assesQuestions)
        ) {
          let categoryName = _.find(_.get(this.props, "categorySort"), {
            categorySortId: prevState.activeCategory
          }).categoryName;
          return {
            activePage: prevState.activePage,
            activeCategory: prevState.activeCategory,
            activeCategoryName: categoryName
          };
        } else {
          let categoryName = _.find(_.get(this.props, "categorySort"), {
            categorySortId: prevState.activeCategory + 1
          }).categoryName;
          return {
            activePage: 1,
            activeCategory: prevState.activeCategory + 1,
            activeCategoryName: categoryName
          };
        }
      },
      () => {}
    );
  };

  getQuestionCount = assesQuestions => {
    return _.get(assesQuestions, "length", 0);
  };

  handlePaginationChange = ({ activePage }) => {
    let categorySortId = _.find(_.get(this.props, "assesQuestions"), {
      questionNumber: activePage
    }).categorySortId;
    let categoryName = _.find(_.get(this.props, "categorySort"), {
      categorySortId: categorySortId
    }).categoryName;
    this.setState({
      activePage: activePage,
      activeCategory: categorySortId,
      activeCategoryName: categoryName
    });
  };

  render() {
    let { type, state } = this.state;
    let paysuccess = _.isEqual(_.get(this.props, "paymentStatus"), "SUCCESS");
    let qasuccess =
      _.isEqual(_.get(this.props, "progress"), 100) ||
      _.isEqual(_.get(this.props, "assesment.status"), "COMPLETED");
    let resultsuccess = _.isEqual(
      _.get(this.props, "assesment.status", ""),
      "COMPLETED"
    );
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-12">
            <span className={HeaderText}>Assessment</span>
          </div>
          {state !== "report" ? (
            <Fragment>
              <div className="col-md-3 col-sm-6 col-12">
                <AssesmentTab
                  type="Payment"
                  text="Pay for the assessment"
                  selected={type === "payment"}
                  done={paysuccess}
                  onClick={() => {
                    this.onStepClick("payment");
                  }}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-12">
                <AssesmentTab
                  type="Q & A"
                  text="Answer all questions"
                  selected={type === "qa"}
                  done={qasuccess}
                  onClick={() => {
                    if (paysuccess) {
                      this.onStepClick("qa");
                    }
                  }}
                />
              </div>
            </Fragment>
          ) : null}
          <div className="col-md-3 col-sm-6 col-12">
            <AssesmentTab
              type="Result"
              text="View your results"
              selected={type === "result"}
              done={resultsuccess}
              onClick={() => {
                if (qasuccess) {
                  this.onStepClick("result");
                }
              }}
            />
          </div>
        </div>
        <div className="row">{this.getFrame(this.state)}</div>
        <div className="row">
          <div className="col-12"></div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    isAllCompleted: state.assesment.isAllCompleted,
    isLastAnswered: state.assesment.isLastAnswered,
    moreinfo: state.assesment.moreinfo,
    paystatus: state.assesment.paystatus,
    paymentStatus: state.assesment.paymentStatus,
    progress: state.assesment.progress,
    categoryScores: state.assesment.categoryScores,
    totalScore: state.assesment.totalScore,
    paymentstatus: state.assesment.paymentstatus,
    assessmentId: state.assesment.assessmentId,
    assesments: state.assesment.assesments,
    assesment: state.assesment.assesment,
    assesQuestionsReport: state.assesment.assesQuestionsReport,
    assesQuestions: state.assesment.assesQuestions,
    categorySort: state.assesment.categorySort,
    categories: state.category.categories,
    questions: state.question.questions,
    loading: state.assesment.loading,
    totalQuestions: state.assesment.totalQuestions,
    stripeStatus: state.assesment.stripeStatus,
    stripeMessage: state.assesment.stripeMessage
  };
};

let mapDispatchToProps = dispatch => {
  return {
    listAllAssesment: () => {
      dispatch(ListAllAssesment());
    },
    createAssesment: data => {
      dispatch(CreateAssesment(data));
    },
    getAssesment: data => {
      dispatch(GetAssesment(data));
    },
    getAssesmentQuestion: data => {
      dispatch(GetAssesmentQuestion(data));
    },
    updateAssesment: data => {
      dispatch(UpdateAssesment(data));
    },
    updateAssesmentQuestion: data => {
      dispatch(UpdateAssesmentQuestion(data));
    },
    updateAssesmentPayment: (data, cb) => {
      dispatch(UpdateAssesmentPayment(data));
      cb();
    },
    listAllCategories: () => {
      dispatch(ListAllCategories());
    },
    listAllQuestions: () => {
      dispatch(ListAllQuestions());
    },
    getMoreInfo: (categoryId, questionId) => {
      dispatch(GetMoreInfo(categoryId, questionId));
    },
    clearStripe: () => {
      dispatch(ClearStripe());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(withRouter(Assesment)));
