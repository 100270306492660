import React from "react";
import { Number, Text, SubText, Reactangle } from "./NotFound.module.scss";
import Navigation from "./Navigation";

function NotFound() {
  return (
    <Navigation type="public">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-12">
            <div className="row">
              <div className="col-md-2 col-sm-12 col-12"></div>
              <div className="col-md-10 col-sm-12 col-12">
                <p className={Number}>404</p>
              </div>
              <div className="col-md-2 col-sm-12 col-12"></div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-12 col-12"></div>
              <div className="col-md-10 col-sm-12 col-12">
                <p className={Text}>Page not found</p>
              </div>
              <div className="col-md-2 col-sm-12 col-12"></div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-12 col-12"></div>
              <div className="col-md-8 col-sm-12 col-12">
                <div className={Reactangle}></div>
              </div>
              <div className="col-md-2 col-sm-12 col-12"></div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-12 col-12"></div>
              <div className="col-md-10 col-sm-12 col-12">
                <p className={SubText}>
                  Sorry, we can’t find the page you were looking for.
                </p>
              </div>
              <div className="col-md-2 col-sm-12 col-12"></div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12">
            <img
              src="assets/group.png"
              srcSet="assets/group@2x.png 2x,assets/group@3x.png 3x"
            />
          </div>
        </div>
      </div>
    </Navigation>
  );
}

export default NotFound;
