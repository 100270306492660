import React, { Component, Fragment } from "react";
import {
  QnASegment,
  QuestionText,
  QuestionTextWrapper,
  MoreInformatio,
  Yes,
  No,
  InProgress,
  Draft,
  Result,
  Arrow,
  Category,
  CategoryText,
  Pending,
  QuestionNo,
  ButtonGroup,
  NavigateSection,
  Model,
  ModelBackground,
  MoreQuestion,
  Why,
  Answer,
  MoreInfo,
  GoIt,
  ResultActive,
  QuizButtonIcon,
  AnswerYes,
  AnswerNo,
  AnswerInProgress,
  YouAnswered,
  AnsYes,
  AnsNo,
  AnsPending,
  AnsSkipped
} from "./QnA.module.scss";
import Pagination from "./Pagination";
import Linkify from "react-linkify";
import _ from "lodash";
import { ModalTitle } from "./../Public/Modal.module.scss";

class QnA extends Component {
  state = {
    modalOpen: false,
    page: 1
  };

  isQuestionsPeding = (questions, category) => {
    return !_.isEmpty(
      _.filter(
        _.filter(questions, { categoryId: category.categoryId }),
        question => _.isUndefined(question.answer)
      )
    );
  };

  getCategories = (categories, selected) => {
    return (
      categories &&
      categories.map(category => {
        return (
          <div
            className={Category}
            key={category.categoryId}
            style={{
              backgroundColor:
                category.categorySortId === selected ? "#171941" : null
            }}
            onClick={(e, c) => {
              this.props.selectCategory(category.categorySortId);
              this.props.handleCategoryClick(e, category.categoryName);
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-8 col-8">
                  <p className={CategoryText}>
                    {category.categoryName}{" "}
                    {this.isQuestionsPeding(this.props.questions, category) ? (
                      <span className={Pending} />
                    ) : null}
                  </p>
                </div>

                <div className="col-md-4 col-sm-4 col-4">
                  <div className={QuestionNo}>{category.totalQuestions}</div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  getImageByCategory = (categories, categorySortId) => {
    return _.get(
      _.find(
        categories,
        category => category.categorySortId === categorySortId
      ),
      "categoryImageUrl"
    );
  };

  getAnswered = answer => {
    switch (answer) {
      case "YES":
        return <span className={AnsYes}>Yes</span>;
      case "NO":
        return <span className={AnsNo}>No</span>;
      case "IN_PROGRESS":
        return <span className={AnsPending}>In progress</span>;
      default:
        return <span className={AnsSkipped}>Didn’t answer</span>;
    }
  };

  render() {
    let {
      categories,
      question,
      progress,
      markAnswer,
      routeTo,
      onStepClick,
      activeCategory,
      paymentStatus,
      completeAssesment,
      getMoreInfo,
      moreinfo,
      activePage,
      getQuestionCount,
      handlePaginationChange
    } = this.props;

    return (
      <div className={QnASegment}>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12 col-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "40px" }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  {this.getCategories(categories, activeCategory)}
                </div>
              </div>
            </div>
            <div
              className="col-md-9 col-sm-12 col-12"
              style={{
                backgroundImage: `url(${this.getImageByCategory(
                  categories,
                  activeCategory
                )})`,
                height: "500px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                opacity: "0.8"
              }}
            >
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "40px" }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12" />
                <div className="col-md-10 col-sm-12 col-12">
                  {_.get(question, "question.title", null) ? (
                    <Fragment>
                      <div className={QuestionTextWrapper}>
                        <p className={QuestionText}>
                          {_.get(question, "questionNumber", null)}.{" "}
                          {_.get(question, "question.title", null)}
                        </p>
                        <span
                          className={MoreInformatio}
                          onClick={() =>
                            getMoreInfo(
                              question.categoryId,
                              question.questionId
                            )
                          }
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          Need more information?
                        </span>
                      </div>
                    </Fragment>
                  ) : (
                    <div>
                      <div
                        className="animated-background"
                        style={{ width: "100%" }}
                      ></div>
                      <div
                        className="animated-background"
                        style={{ width: "80%" }}
                      ></div>
                      <div
                        className="animated-background"
                        style={{ width: "60%" }}
                      ></div>
                    </div>
                  )}
                </div>
                <div className="col-md-1 col-sm-12 col-12" />
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12" />
                <div className="col-md-10 col-sm-12 col-12">
                  <p className={YouAnswered}>
                    You answered{" "}
                    {this.getAnswered(_.get(question, "answer", null))}
                  </p>
                </div>
                <div className="col-md-1 col-sm-12 col-12" />
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12" />
                <div className="col-md-10 col-sm-12 col-12">
                  <div style={{ height: "14px" }} />
                </div>
                <div className="col-md-1 col-sm-12 col-12" />
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12"></div>
                <div className="col-md-3 col-sm-4 col-4">
                  <button
                    className={
                      _.get(question, "answer", null) === "YES"
                        ? AnswerYes
                        : Yes
                    }
                    onClick={e => {
                      markAnswer(
                        question.questionId,
                        question.categoryId,
                        question.assessmentId,
                        "YES"
                      );
                    }}
                  >
                    <img
                      className={QuizButtonIcon}
                      src={
                        _.get(question, "answer", null) === "YES"
                          ? "assets/yes-answered.svg"
                          : "assets/yes.svg"
                      }
                    />{" "}
                    YES
                  </button>
                </div>
                <div className="col-md-3 col-sm-4 col-4">
                  <button
                    className={
                      _.get(question, "answer", null) === "NO" ? AnswerNo : No
                    }
                    onClick={e => {
                      markAnswer(
                        question.questionId,
                        question.categoryId,
                        question.assessmentId,
                        "NO"
                      );
                    }}
                  >
                    <img
                      className={QuizButtonIcon}
                      src={
                        _.get(question, "answer", null) === "NO"
                          ? "assets/no-answered.svg"
                          : "assets/no.svg"
                      }
                    />{" "}
                    NO
                  </button>
                </div>
                <div className="col-md-3 col-sm-4 col-4">
                  <button
                    className={
                      _.get(question, "answer", null) === "IN_PROGRESS"
                        ? AnswerInProgress
                        : InProgress
                    }
                    onClick={e => {
                      markAnswer(
                        question.questionId,
                        question.categoryId,
                        question.assessmentId,
                        "IN_PROGRESS"
                      );
                    }}
                  >
                    <img
                      className={QuizButtonIcon}
                      src={
                        _.get(question, "answer", null) === "IN_PROGRESS"
                          ? "assets/pending-answered.svg"
                          : "assets/pending.svg"
                      }
                    />{" "}
                    In Progress
                  </button>
                </div>
                <div className="col-md-1 col-sm-12 col-12"></div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "40px" }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12"></div>
                <div className="col-md-10 col-sm-12 col-12">
                  <div className="progress-container progress-primary">
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-warning"
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 col-sm-12 col-12"></div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "20px" }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-1 col-12" />
                <div className="col-md-2 col-sm-3 col-12">
                  <button
                    className={Draft}
                    onClick={() =>
                      paymentStatus
                        ? routeTo("home", null)
                        : onStepClick("payment")
                    }
                  >
                    Draft and exit
                  </button>
                </div>
                <div
                  className="col-md-6 col-sm-4 col-12"
                  style={{ textAlign: "center" }}
                >
                  <Pagination
                    hideFirstAndLastPageLinks
                    progress={progress}
                    totalPages={100}
                    className="pagination"
                    currentPage={
                      getQuestionCount >= activePage ? activePage : 0
                    }
                    onChange={page =>
                      handlePaginationChange({ activePage: page })
                    }
                  />
                </div>
                <div className="col-md-2 col-sm-3 col-12">
                  <button
                    disabled={progress < 100}
                    className={progress === 100 ? ResultActive : Result}
                    onClick={() => {
                      completeAssesment(null, () => {
                        onStepClick("result");
                      });
                    }}
                  >
                    Result
                  </button>
                </div>
                <div className="col-md-1 col-sm-1 col-12" />
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ marginBottom: "14px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* More Informatio Modal */}
        <div
          className={`${ModelBackground} modal fade bd-example-modal-lg`}
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className={`${Model} modal-content`}>
              <div className="modal-header">
                <h5
                  className={`modal-title ${ModalTitle}`}
                  id="exampleModalLabel"
                  style={{ color: "#ffffff" }}
                >
                  More Info
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    {_.get(moreinfo, "title", "") ? (
                      <span className={MoreQuestion}>
                        {_.get(moreinfo, "title", "")}
                      </span>
                    ) : (
                      <div>
                        <div
                          className="animated-background-green"
                          style={{ width: "75%" }}
                        ></div>
                        <div
                          className="animated-background-green"
                          style={{ width: "50%" }}
                        ></div>
                        <div
                          className="animated-background-green"
                          style={{ width: "30%" }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-body row">
                <div className="col-md-12">
                  <span className={Why}>Why is this needed for AI?</span>
                </div>
                <div className="col-md-12">
                  {_.get(moreinfo, "why", "") ? (
                    <span className={`${Answer} ${MoreInfo}`}>
                      <Linkify
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key
                        ) => (
                          <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}
                      >
                        {_.get(moreinfo, "why", "")}{" "}
                      </Linkify>
                    </span>
                  ) : (
                    <div>
                      <div
                        className="animated-background"
                        style={{ width: "90%" }}
                      ></div>
                      <div
                        className="animated-background"
                        style={{ width: "65%" }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-body row">
                <div className="col-md-12">
                  <span className={Why}>
                    How do you acquire this capability?
                  </span>
                </div>
                <div className="col-md-12">
                  {_.get(moreinfo, "how", "") ? (
                    <span className={`${Answer} ${MoreInfo}`}>
                      {" "}
                      <Linkify
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key
                        ) => (
                          <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}
                      >
                        {_.get(moreinfo, "how", "")}
                      </Linkify>
                    </span>
                  ) : (
                    <div>
                      <div
                        className="animated-background"
                        style={{ width: "90%" }}
                      ></div>
                      <div
                        className="animated-background"
                        style={{ width: "65%" }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`${GoIt} btn btn-primary`}
                  data-dismiss="modal"
                >
                  Got it
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QnA;
