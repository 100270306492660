import Auth from "@aws-amplify/auth";
import _ from "lodash";
//import Analytics from "@aws-amplify/analytics";

export const getIdToken = async () => {
  const creds = await Auth.currentSession();
  return creds.idToken.jwtToken;
};

export const isAuthenticated = () => {
  return !_.isEmpty(
    _.pickBy(localStorage, (value, key) => {
      return _.includes(key.toString(), "userData");
    })
  );
};

export const isEmailVerified = () => {
  return (
    JSON.parse(
      Object.values(
        _.pickBy(localStorage, (value, key) => {
          return _.includes(key.toString(), "userData");
        })
      )[0]
    )["UserAttributes"][1].Value === "true"
  );
};

const clearState = () => {
  localStorage.removeItem("airr_meta");
};

const recordAnalyticsEvent = event => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

const recordAnalytics = (userId, email) => {
  /*    Analytics.updateEndpoint({
        userId,
        address: email,
        channelType: "EMAIL"
    });*/
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    user_id: userId,
    email: email
  });
  /*    let _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
        "identify",
        {
            email: email,
            id: userId
        }
    ]);
    window.HubSpotConversations.widget.refresh();*/
};

export const SignUp = ({ username, password }, cb) => {
  Auth.signUp({
    username: username,
    password: password
  })
    .then(success => {
      let { userSub } = success;
      recordAnalytics(userSub, username);
      recordAnalyticsEvent({ event: "sign_up", method: "email" });
      cb(null, success);
    })
    .catch(err => cb(err, null));
};

export const SignIn = ({ username, password }, cb) => {
  Auth.signIn(username, password)
    .then(success => {
      let { username: userSub } = success;
      recordAnalytics(userSub, username);
      recordAnalyticsEvent({ event: "login", method: "email" });
      recordAnalyticsEvent({ event: "chat_load" });
      cb(null, success);
    })
    .catch(err => cb(err, null));
};

export const SignOut = cb => {
  Auth.signOut()
    .then(data => {
      clearState();
      cb(null, data);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const ForgotPassword = ({ username }, cb) => {
  Auth.forgotPassword(username)
    .then(data => {
      cb(null, data);
    })
    .catch(err => {
      cb(err, null);
    });
};

export const ForgotPasswordSubmit = ({ username, code, new_password }, cb) => {
  Auth.forgotPasswordSubmit(username, code, new_password)
    .then(data => {
      cb(null, data);
    })
    .catch(err => {
      cb(err, null);
    });
};
