import axios from "axios";
import { getIdToken } from "../Util/Auth";
import {
  GET_USERS,
  UPDATE_USER,
  ERROR_USER,
  LOADING,
  CLEAR_USER_STATE,
  UPDATE_NOTIFICATION
} from "../Constants/Users";

let baseUrl = process.env.REACT_APP_BASE_URL;

export const GetUser = () => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  axios
    .get(`${baseUrl}/users/`, {
      headers: { Authorization: await getIdToken() }
    })
    .then(data => dispatch({ type: GET_USERS, payload: data.data }))
    .catch(error => dispatch({ type: ERROR_USER, payload: error }));
};

export const UpdateUser = data => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  axios
    .put(`${baseUrl}/users`, data, {
      headers: { Authorization: await getIdToken() }
    })
    .then(data => dispatch({ type: UPDATE_USER, payload: data.data }))
    .catch(error => dispatch({ type: ERROR_USER, payload: error }));
};

export const ClearUser = () => async dispatch => {
  dispatch({ type: CLEAR_USER_STATE });
};

export const setLoading = data => async dispatch => {
  dispatch({ type: LOADING, payload: data });
};

export const UpdateNotification = () => dispatch => {
  dispatch({ type: UPDATE_NOTIFICATION, payload: false });
};
