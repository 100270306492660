import React, { Component, Fragment } from "react";
import {
  PaymentSegment,
  Sheild,
  Safe,
  Pay,
  Amount,
  USD,
  ButtonText,
  EnterMessage,
  SafeMoneyTransfer,
  PaymentStatus,
  AmountWrapper
} from "./Payment.module.scss";
import {
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import CreditCard from "./CreditCard";
import { withToastManager } from "react-toast-notifications";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
const stripeStyle = {
  style: {
    base: {
      fontSize: "13px",
      color: "#f4f5f7",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#9e2146"
    }
  }
};

class Payment extends Component {
  state = {
    brand: null
  };

  handleSubmit = async (event, stripe, elements) => {
    event.preventDefault();
    let { token } = await stripe.createToken(
      elements.getElement(CardNumberElement),
      {
        name: event.target.elements[0].value,
        address_line1: event.target.elements[1].value
      }
    );
    if (_.isUndefined(token)) {
      this.props.toastManager.add(
        "Invalid payment details! Please check and try again",
        {
          appearance: "warning",
          autoDismiss: true
        }
      );
    } else {
      this.props.makepaymnet(token);
    }
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
      errorMessage: null
    });
  };

  handleCardChange = ({ empty, complete, brand }) => {
    if (!empty && complete && brand) {
      this.setState({ brand: brand });
    } else if (!complete) {
      this.setState({ brand: null });
    }
  };

  InjectedCheckoutForm = () => (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <form
            onSubmit={e => {
              this.handleSubmit(e, stripe, elements);
            }}
          >
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder="Card Holder Name"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="address_line1"
                name="address_line1"
                className="form-control"
                placeholder="Address"
              />
            </div>
            <div className="form-row">
              <div className="form-group">
                <div className="row" style={{ margin: "7px" }}>
                  <div className="col-md-12 col-sm-12 col-12">
                    <p>
                      <img src="assets/sheild.svg" className={Sheild} />
                      <span className={SafeMoneyTransfer}>
                        Safe money transfer using your preferred credit or debit
                        card.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group input-group">
              <CardNumberElement
                className="form-control"
                options={stripeStyle}
                onChange={this.handleCardChange}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <CreditCard card={this.state.brand} />
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <CardExpiryElement
                  options={stripeStyle}
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <CardCvcElement
                  options={stripeStyle}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <span className={Amount}>
                  <p className={AmountWrapper}>
                    Amount <b className={USD}> USD 1.00</b>
                  </p>
                </span>
              </div>
              <div className="form-group col-md-2"></div>
              <div className="form-group col-md-4">
                <button className="btn btn-primary" disabled={!stripe}>
                  <span className="ButtonText">
                    <img src="assets/safe.svg" className={Safe} />
                    Pay Now
                  </span>
                </button>
              </div>
            </div>
          </form>
        )}
      </ElementsConsumer>
    </Elements>
  );

  render() {
    let { done } = this.props;
    return (
      <div className={PaymentSegment}>
        <div className="row">
          <div className="col-md-5 col-sm-12 col-12">
            <picture>
              <source srcSet="assets/Payment_HD.webp" type="image/webp" />
              <source srcSet="assets/Payment_HD.png" type="image/png" />
              <img src="assets/Payment_HD.png" />
            </picture>
          </div>
          <div className="col-md-6 col-sm-12 col-12">
            <div className="container-fluid" style={{ height: "100%" }}>
              {done ? (
                <Fragment>
                  <div className="row">
                    <div className="col-md-2 col-sm-12 col-12"></div>
                    <div
                      className="col-md-8 col-sm-12 col-12"
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        left: "25%",
                        top: "25%"
                      }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <svg
                            id="successAnimation"
                            className="animated"
                            xmlns="http://www.w3.org/2000/svg"
                            width="200"
                            height="200"
                            viewBox="0 0 70 70"
                          >
                            <circle
                              id="successAnimationCircle"
                              cx="35"
                              cy="35"
                              r="24"
                              stroke="rgb(150, 230, 161)"
                              strokeWidth="2"
                              strokeLinecap="round"
                              fill="transparent"
                            />
                            <polyline
                              id="successAnimationCheck"
                              stroke="rgb(150, 230, 161)"
                              strokeWidth="2"
                              points="23 34 34 43 47 27"
                              fill="transparent"
                            />
                          </svg>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12">
                          <p className={PaymentStatus}>Payment Successful!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="row">
                    <div className="col-md-12">
                      <span style={{ margin: "28px" }}></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <span className={EnterMessage}>
                        Enter your billing details
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <span style={{ margin: "28px" }}></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-sm-1 col-1"></div>
                    <div className="col-md-12 col-sm-10 col-10">
                      {this.InjectedCheckoutForm()}
                    </div>
                    <div className="col-sm-1 col-1"></div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(Payment);
