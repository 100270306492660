import React, { Component } from "react";
import AssesmentCard from "./../AssesmentCard";
import {
  ResultSegment,
  AssesmentsRow,
  Category,
  Yes,
  No,
  Pending,
  Arrow,
  Finish,
  StatusRow,
  StatusText,
  StatusNumber
} from "./Result.module.scss";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import AssesmentResultRow from "./AssesmentResultRow";

class Result extends Component {
  getStatusRows = (categories, questions) => {
    return (
      categories &&
      categories.map(category => {
        return (
          <AssesmentResultRow
            key={category.categoryId}
            questions={_.get(
              _.find(questions, {
                categoryName: category.categoryName
              }),
              "questions",
              []
            )}
            name={category.categoryName}
            yes={category.yesCount}
            no={category.noCount}
            pending={category.inProgressCount}
            moreinfo={this.props.moreinfo}
            getMoreInfo={this.props.getMoreInfo}
            type={this.props.reportType}
          />
        );
      })
    );
  };

  routeTo = (route, state) => {
    this.props.history.push(`/${route}`, state);
  };

  render() {
    let { categoryScores, totalScore, assesQuestionsReport, type } = this.props;
    return (
      <div className={ResultSegment}>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-12">
              <AssesmentCard
                type="result"
                total={totalScore}
                categories={categoryScores}
              />
            </div>
            <div className="col-md-9 col-sm-6 col-12">
              {this.getStatusRows(categoryScores, assesQuestionsReport)}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-3 col-12"></div>
            <div className="col-md-6 col-sm-6 col-12"></div>
            <div className="col-md-3 col-sm-3 col-12">
              <button
                className={Finish}
                onClick={() => this.routeTo("home", null)}
              >
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Result);
