import React, { Component, Fragment } from "react";
import {
  Text1,
  Text2,
  Start,
  StartText,
  Arrow,
  Image1,
  Frame1,
  Benefits1,
  Benefits2,
  BenifitsText,
  Blist,
  Frame2,
  Solution1,
  Solution2,
  Slist,
  WhiteFrame,
  About1,
  About2,
  AboutText,
  Experience,
  FooterText,
  FooterIcons,
  FooterLogo,
  Services1,
  Services2,
  ServicesText,
  AppStores,
  Image2,
  ContFlu,
  FooterTextCopyright
} from "./Public.module.scss";
import Particles from "react-particles-js";
import TermsOfUse from "./Public/TermsOfUse";
import PrivacyPolicy from "./Public/PrivacyPolicy";
import { withRouter } from "react-router-dom";

class Public extends Component {
  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  render() {
    return (
      <Fragment>
        <div
          className={`container-fluid ${ContFlu}`}
          style={{ width: "100%", position: "relative" }}
        >
          <Particles
            style={{ width: "100%", position: "absolute", zIndex: "-1" }}
            params={{
              particles: {
                number: {
                  value: 50
                },
                size: {
                  value: 3
                }
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "repulse"
                  }
                }
              }
            }}
          />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div style={{ margin: "14px" }} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 col-sm-12 col-12"></div>
            <div
              className="col-md-2 col-sm-12 col-12"
              style={{ textAlign: "center" }}
            >
              <img alt={"airr-logo"} src="assets/logo-horizontal-color.svg" />
            </div>
            <div className="col-md-5 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-1 col-sm-12 col-12"></div>
            <div className="col-md-10 col-sm-12 col-12">
              <p className={Text1}>
                Artificial Intelligence Readiness Made Simple
              </p>
            </div>
            <div className="col-md-1 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-12 col-12"></div>
            <div className="col-md-8 col-sm-12 col-12"></div>
            <div className="col-md-2 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-12 col-12"></div>
            <div className="col-md-8 col-sm-12 col-12">
              <p className={Text2}>
                AIRR solutions allow you to manage Artificial Intelligence
                projects like Machine Learning at the right cost and with ease
              </p>
            </div>
            <div className="col-md-2 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-12 col-12"></div>
            <div className="col-md-8 col-sm-12 col-12"></div>
            <div className="col-md-2 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 col-12"></div>
            <div
              className="col-md-4 col-sm-12 col-12"
              style={{ textAlign: "center" }}
            >
              <button
                id="btnGetStarted"
                className={Start}
                onClick={() => this.routeTo("register")}
              >
                <span className={StartText}>Get started now</span>
                <img className={Arrow} src="assets/arrow-right.svg" alt="" />
              </button>
            </div>
            <div className="col-md-4 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-12 col-12"></div>
            <div className="col-md-8 col-sm-12 col-12">
              <div style={{ margin: "150px" }} />
            </div>
            <div className="col-md-2 col-sm-12 col-12"></div>
          </div>
        </div>
        <div className={`container-fluid ${ContFlu}`}>
          <div className="row">
            <div className="col-md-7 col-sm-12 col-12">
              <picture>
                <source
                  className={Image1}
                  srcSet="assets/Landing-1_HD.webp"
                  type="image/webp"
                />
                <source
                  className={Image1}
                  srcSet="assets/Landing-1_HD_800.png"
                  type="image/png"
                />
                <img
                  alt={"self-assessment"}
                  className={Image1}
                  src="assets/Landing-1_HD_800.png"
                />
              </picture>
            </div>
            <div className="col-md-5 col-sm-12 col-12">
              <div className={Frame1}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-1 col-sm-12 col-12"></div>
                    <div className="col-md-10 col-sm-12 col-12">
                      <p className={Benefits1}>Benefits</p>
                      <p className={Benefits2}>Benefits</p>
                    </div>
                    <div className="col-md-1 col-sm-12 col-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-1 col-sm-12 col-12"></div>
                    <div className="col-md-10 col-sm-12 col-12">
                      <p className={BenifitsText}>
                        Have access to penetrating UI of management questions to
                        determine if your firm is ready to meet your AI project
                        needs.
                      </p>
                    </div>
                    <div className="col-md-1 col-sm-12 col-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div style={{ margin: "50px" }} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1 col-sm-12 col-12"></div>
                    <div className="col-md-10 col-sm-12 col-12">
                      <ul className={Blist}>
                        <li>Cost effective</li>
                        <li>Quick integration</li>
                        <li>Clear result</li>
                      </ul>
                    </div>
                    <div className="col-md-1 col-sm-12 col-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-12 col-12"></div>
            <div className="col-md-8 col-sm-12 col-12">
              <div style={{ margin: "150px" }} />
            </div>
            <div className="col-md-2 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-7 col-sm-12 col-12"></div>
            <div className="col-md-5 col-sm-12 col-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <p className={Services1}>Services</p>
                  <p className={Services2}>Services</p>
                </div>
                <div className="col-md-12 col-sm-12 col-12">
                  <p className={ServicesText}>
                    Covering 7 business functions and applicable in over 100
                    countries, AIRR manages your AI development projects and
                    dramatically improves your agility to bring new AI projects
                    to fruition at the lowest cost and in the fastest time
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 col-sm-12 col-12">
              <div className={Frame2}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-1 col-sm-12 col-12"></div>
                    <div className="col-md-10 col-sm-12 col-12">
                      <p className={Solution1}>Solution</p>
                      <p className={Solution2}>Solution</p>
                    </div>
                    <div className="col-md-1 col-sm-12 col-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div style={{ margin: "50px" }} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1 col-sm-12 col-12"></div>
                    <div className="col-md-10 col-sm-12 col-12">
                      <ul className={Slist}>
                        <li>Our online assessment instrument.</li>
                        <li>
                          Incorporates 6 key areas of your business systems.
                        </li>
                        <li>Authored by a career CIO.</li>
                        <li>Gives actionable data and a Roadmap.</li>
                        <li>Success for your AI initiatives.</li>
                        <li>
                          Less cost, quicker, deliver the promised results.
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-1 col-sm-12 col-12"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-12 col-12">
              <picture>
                <source
                  className={Image2}
                  srcSet="assets/Landing-2_HD.webp"
                  type="image/webp"
                />
                <source
                  className={Image2}
                  srcSet="assets/Landing-2_HD_800.png"
                  type="image/png"
                />
                <img
                  alt={"actionable-report"}
                  className={Image2}
                  src="assets/Landing-2_HD_800.png"
                />
              </picture>
            </div>
          </div>
          <div className={`container-fluid ${ContFlu} ${WhiteFrame}`}>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div style={{ margin: "150px" }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-12 col-12"></div>
              <div className="col-md-10 col-sm-12 col-12">
                <p className={About1}>About</p>
                <p className={About2}>About</p>
              </div>
              <div className="col-md-1 col-sm-12 col-12"></div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-12 col-12"></div>
              <div className="col-md-10 col-sm-12 col-12">
                <p className={AboutText}>
                  InCite Logix supplies both software and services necessary to
                  help our Clients solve complex business problems. We focus on
                  using Data as a competitive advantage. We utilize unique tools
                  and methodologies to deliver faster, more accurate decision
                  support. We work with Clients to deliver successful projects
                  using pattern-recognition and forecasting (AI) and algorithms
                  that capture workflow statistics (machine learning), providing
                  foundations for step-change improvements and enhanced
                  competitive advantage. We are a privately held start-up,
                  located in Houston, Texas. We have unique experience in
                  Refining, Chemical, Midstream and Petrochemical production,
                  maintenance, reliability and safety
                </p>
              </div>
              <div className="col-md-1 col-sm-12 col-12"></div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div style={{ margin: "150px" }} />
              </div>
            </div>
          </div>
          <div className={`container-fluid ${ContFlu}`}>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div style={{ margin: "150px" }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-12 col-12"></div>
              <div className="col-md-10 col-sm-12 col-12">
                <p className={Experience}>
                  Experience the next level of Artificial Intelligence Readiness
                </p>
              </div>
              <div className="col-md-1 col-sm-12 col-12"></div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-12 col-12"></div>
              <div className="col-md-10 col-sm-12 col-12">
                <div className="row">
                  <div className="col-md-1 col-sm-12 col-12"></div>
                  <div className={`col-md-5 col-sm-12 col-12 ${AppStores}`}>
                    <a
                      to="route"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://apps.apple.com/lk/app/ai-readiness-roadmap/id1492344467"
                    >
                      <img alt={"app-store"} src="assets/AppStore.svg" />
                    </a>
                  </div>
                  <div className={`col-md-5 col-sm-12 col-12 ${AppStores}`}>
                    <a
                      to="route"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.incitelogix.airr.mobile"
                    >
                      <img alt={"google-play"} src="assets/GooglePlay.svg" />
                    </a>
                  </div>
                  <div className="col-md-1 col-sm-12 col-12"></div>
                </div>
              </div>
              <div className="col-md-1 col-sm-12 col-12"></div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div style={{ margin: "150px" }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 col-sm-6 col-12" />
              <div className="col-md-2 col-sm-6 col-12">
                <div className="row">
                  <div className="col-md-12">
                    <img
                      alt={"arr-logo-flat"}
                      src="assets/logo-flat.svg"
                      className={FooterLogo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12">
                <div className="row">
                  <div className="col-md-2 col-sm-2 col-2">
                    <img
                      alt={"place-icon"}
                      src="assets/place.svg"
                      className={FooterIcons}
                    />
                  </div>
                  <div className="col-md-10 col-sm-10 col-10">
                    <p className={FooterText}>
                      InCite Logix 2101 Citywest Blvd. Suite 100 Houston, TX
                      77042
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-sm-2 col-2">
                    <img
                      alt={"phone-icon"}
                      src="assets/phone.svg"
                      className={FooterIcons}
                    />
                  </div>
                  <div className="col-md-10 col-sm-10 col-10">
                    <p className={FooterText}>833-722-2555</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12">
                <div className="row">
                  <div className="col-md-2 col-sm-2 col-2">
                    <a
                      to="route"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/InCiteLogix/"
                    >
                      <img
                        alt={"facebook"}
                        src="assets/facebook.svg"
                        className={FooterIcons}
                      />
                    </a>
                  </div>
                  <div className="col-md-10 col-sm-10 col-10">
                    <a
                      to="route"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/InCiteLogix/"
                    >
                      <p className={FooterText}>Facebook</p>
                    </a>
                  </div>
                  <div className="col-md-2 col-sm-2 col-2">
                    <a
                      to="route"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/company/incite-logix/"
                    >
                      <img
                        alt={"linkedin"}
                        src="assets/linkedin.svg"
                        className={FooterIcons}
                      />
                    </a>
                  </div>
                  <div className="col-md-10 col-sm-10 col-10">
                    <a
                      to="route"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/company/incite-logix/"
                    >
                      <p className={FooterText}>Linkedin</p>{" "}
                    </a>
                  </div>
                  <div className="col-md-2 col-sm-2 col-2">
                    <a
                      to="route"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://twitter.com/@LogixIn"
                    >
                      <img
                        alt={"twitter"}
                        src="assets/twitter.svg"
                        className={FooterIcons}
                      />
                    </a>
                  </div>
                  <div className="col-md-10 col-sm-10 col-10">
                    <a
                      to="route"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://twitter.com/@logix_in"
                    >
                      <p className={FooterText}>Twitter</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-6 col-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <p
                      className={FooterText}
                      data-toggle="modal"
                      data-target="#TermsModal"
                      style={{ cursor: "pointer" }}
                    >
                      Terms of Use
                    </p>
                  </div>
                  <div className="col-md-12 col-sm-12 col-12">
                    <p
                      className={FooterText}
                      data-toggle="modal"
                      data-target="#PrivacyModal"
                      style={{ cursor: "pointer" }}
                    >
                      Privacy Policy
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-6 col-12" />
            </div>
            <div className="row justify-content-center">
              <span className={FooterTextCopyright}>
                © Copyright 2020{" "}
                <a
                  className={FooterTextCopyright}
                  href="https://incitelogix.com"
                  target="_blank"
                >
                  InCiteLogix
                </a>
              </span>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div style={{ margin: "30px" }} />
              </div>
            </div>
          </div>
        </div>
        {/** Terms of use */}
        <div
          className="modal fade"
          id="TermsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="TermsModal">
                  Terms of use
                </h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <TermsOfUse />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/** Privacy policy */}

        <div
          className="modal fade"
          id="PrivacyModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="PrivacyModal">
                  PRIVACY POLICY FOR AIRR
                </h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <PrivacyPolicy />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Public);
