import React, { Component, Fragment } from "react";
import {
  ResultSegment,
  AssesmentsRow,
  Category,
  Yes,
  No,
  Pending,
  Arrow,
  Model,
  QuestionRow,
  StatusRow,
  StatusText,
  StatusNumber,
  MoreInfo,
  Question,
  QuestionNo,
  QuestionYes,
  MoreQuestion,
  Why,
  GoIt,
  Answer,
  ModelBackground,
  QuestionNumber,
  QuestionPending
} from "./Result.module.scss";
import _ from "lodash";
import Linkify from "react-linkify";
import { ModalTitle } from "./../Public/Modal.module.scss";

class AssesmentResultRow extends Component {
  state = {
    open: false,
    title: null,
    why: null,
    how: null
  };

  toggleContent = () =>
    this.setState(({ open }) => {
      return { open: !open };
    });

  questionAnserBox = answer => {
    if (answer === "NO") {
      return (
        <div className={QuestionNo}>
          <span className={QuestionNumber}>No</span>
        </div>
      );
    } else if (answer === "IN_PROGRESS") {
      return (
        <div className={QuestionPending}>
          <span className={QuestionNumber}>Pending</span>
        </div>
      );
    } else {
      return null;
    }
  };

  getQuestions = (questions, type) => {
    if (questions && questions.length > 0) {
      if (type === "assesment") {
        return questions.map(question => {
          return (
            <div className={QuestionRow} key={question.questionNumber}>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "14px" }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <p className={Question}>
                    {question.questionNumber}. {question.title}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12" />
                <div className="col-md-2 col-sm-2 col-2">
                  {this.questionAnserBox(question.answer)}
                </div>
                <div className="col-md-9 col-sm-10 col-10">
                  <span
                    onClick={() => {
                      this.props.getMoreInfo(
                        question.categoryId,
                        question.questionId
                      );
                    }}
                    className={MoreInfo}
                    data-toggle="modal"
                    data-target="#exampleModal1"
                  >
                    Need more information?
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "14px" }} />
                </div>
              </div>
              {/* More Informatio Modal from Assesment */}
              <div
                className={`${ModelBackground} modal fade bd-example-modal-lg`}
                id="exampleModal1"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <div className={`${Model} modal-content`}>
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="exampleModalLabel"
                        style={{ color: "#ffffff" }}
                      >
                        More Info
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          {_.get(this.props, "moreinfo.title", false) ? (
                            <span className={MoreQuestion}>
                              {_.get(this.props, "moreinfo.title", "")}
                            </span>
                          ) : (
                            <div>
                              <div
                                className="animated-background-green"
                                style={{ width: "75%" }}
                              ></div>
                              <div
                                className="animated-background-green"
                                style={{ width: "50%" }}
                              ></div>
                              <div
                                className="animated-background-green"
                                style={{ width: "30%" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-body row">
                      <div className="col-md-12">
                        <span className={Why}>Why is this needed for AI?</span>
                      </div>

                      <div className="col-md-12">
                        {_.get(this.props, "moreinfo.why", false) ? (
                          <span className={Answer}>
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <a
                                  target="blank"
                                  href={decoratedHref}
                                  key={key}
                                >
                                  {decoratedText}
                                </a>
                              )}
                            >
                              {_.get(this.props, "moreinfo.why", "")}{" "}
                            </Linkify>
                          </span>
                        ) : (
                          <div>
                            <div
                              className="animated-background"
                              style={{ width: "90%" }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{ width: "65%" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-body row">
                      <div className="col-md-12">
                        <span className={Why}>
                          How do you acquire this capability?
                        </span>
                      </div>

                      <div className="col-md-12">
                        {_.get(this.props, "moreinfo.how", false) ? (
                          <span className={Answer}>
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <a
                                  target="blank"
                                  href={decoratedHref}
                                  key={key}
                                >
                                  {decoratedText}
                                </a>
                              )}
                            >
                              {_.get(this.props, "moreinfo.how", "")}{" "}
                            </Linkify>
                          </span>
                        ) : (
                          <div>
                            <div
                              className="animated-background"
                              style={{ width: "90%" }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{ width: "65%" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className={`${GoIt} btn btn-primary`}
                        data-dismiss="modal"
                      >
                        Got it
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      } else {
        return questions.map(question => {
          return (
            <div className={QuestionRow} key={question.questionId}>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "14px" }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <p className={Question}>
                    {question.questionNumber}. {question.question.title}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12" />
                <div className="col-md-2 col-sm-2 col-2">
                  {this.questionAnserBox(question.answer)}
                </div>
                <div className="col-md-9 col-sm-10 col-10">
                  <span
                    onClick={() =>
                      this.props.getMoreInfo(
                        question.categoryId,
                        question.questionId
                      )
                    }
                    className={MoreInfo}
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Need more information?
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "14px" }} />
                </div>
              </div>
              {/* More Informatio Modal from Report */}
              <div
                className={`${ModelBackground} modal fade bd-example-modal-lg`}
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <div className={`${Model} modal-content`}>
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="exampleModalLabel"
                        style={{ color: "#ffffff" }}
                      >
                        More Info
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          {_.get(this.props, "moreinfo.title", "") ? (
                            <span className={MoreQuestion}>
                              {_.get(this.props, "moreinfo.title", "")}
                            </span>
                          ) : (
                            <div>
                              <div
                                className="animated-background-green"
                                style={{ width: "75%" }}
                              ></div>
                              <div
                                className="animated-background-green"
                                style={{ width: "50%" }}
                              ></div>
                              <div
                                className="animated-background-green"
                                style={{ width: "30%" }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-body row">
                      <div className="col-md-12">
                        <span className={Why}>Why is this needed for AI?</span>
                      </div>
                      <div className="col-md-12">
                        {_.get(this.props, "moreinfo.why", "") ? (
                          <span className={Answer}>
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <a
                                  target="blank"
                                  href={decoratedHref}
                                  key={key}
                                >
                                  {decoratedText}
                                </a>
                              )}
                            >
                              {_.get(this.props, "moreinfo.why", "")}{" "}
                            </Linkify>{" "}
                          </span>
                        ) : (
                          <div>
                            <div
                              className="animated-background"
                              style={{ width: "90%" }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{ width: "65%" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-body row">
                      <div className="col-md-12">
                        <span className={Why}>
                          How do you acquire this capability?
                        </span>
                      </div>

                      <div className="col-md-12">
                        {_.get(this.props, "moreinfo.how", "") ? (
                          <span className={Answer}>
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <a
                                  target="blank"
                                  href={decoratedHref}
                                  key={key}
                                >
                                  {decoratedText}
                                </a>
                              )}
                            >
                              {_.get(this.props, "moreinfo.how", "")}
                            </Linkify>
                          </span>
                        ) : (
                          <div>
                            <div
                              className="animated-background"
                              style={{ width: "90%" }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{ width: "65%" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className={`${GoIt} btn btn-primary`}
                        data-dismiss="modal"
                      >
                        Got it
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      }
    } else {
      return <div></div>;
    }
  };

  getQuestionLength = questions => {
    return (questions && questions.length) > 1;
  };

  render() {
    let { open } = this.state;
    let { name, yes, no, pending, questions, type } = this.props;
    return (
      <div className={AssesmentsRow}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div style={{ margin: "24px" }} />
            </div>
          </div>
          <div className="row" onClick={this.toggleContent}>
            <div className="col-md-4 col-sm-12 col-12">
              <span className={Category}>{name}</span>
            </div>
            <div className="col-md-1 col-sm-12 col-12"></div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className={StatusRow}>
                <div className={StatusRow}>
                  <div className={Yes}>
                    <span className={StatusNumber}>{yes || 0}</span>
                  </div>
                  <span className={StatusText}>Yes</span>
                </div>
                <div className={StatusRow}>
                  <div className={No}>
                    <span className={StatusNumber}>{no || 0}</span>
                  </div>
                  <span className={StatusText}>No</span>
                </div>
                <div className={StatusRow}>
                  <div className={Pending}>
                    <span className={StatusNumber}>{pending || 0}</span>
                  </div>
                  <span className={StatusText}>Pending</span>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-12 col-12">
              <img
                src={
                  open && this.getQuestionLength(questions)
                    ? "assets/arrow-down.svg"
                    : "assets/arrow-up.svg"
                }
                className={Arrow}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div style={{ margin: "24px" }} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              {open ? this.getQuestions(questions, type) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssesmentResultRow;
