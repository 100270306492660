import React, { Component } from "react";
import {
  Welcome_to_AIRR,
  text_style_1,
  Start_an_Assessment,
  Dont_have_an_account,
  Signup,
  Forgot_password,
  Demo_2,
  Submit,
  TopMargin,
  Signin,
  Arrow
} from "./Login.module.scss";
import _ from "lodash";
import { SignIn } from "../Util/Auth";
import { withRouter, Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import { validateValue, toLowerCase } from "../Util/Validate";

export class Login extends Component {
  state = {
    pass01type: true,
    errorMessage: null,
    loading: false,
    reset: false,
    unerror: false,
    pwerror: false
  };

  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  forgotPassword = () =>
    this.setState(state => {
      return { reset: !state.reset };
    });

  handleOnChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
      errorMessage: null,
      unerror: false,
      pwerror: false
    });
  };
  getInputClass = type => {
    return this.state[type] ? "form-group has-danger" : "form-group";
  };

  handleFieldError = (username, password) => {
    this.setState({
      unerror: validateValue(username, "Username"),
      pwerror: validateValue(password, "Password")
    });
  };

  signIn = () => {
    let { username, password } = this.state;
    if (username && password) {
      this.setState({ loading: true });
      SignIn(
        { username: toLowerCase(username), password },
        (error, success) => {
          this.setState({ loading: false });
          if (error) {
            this.setState({ errorMessage: error.message });
          } else {
            this.routeTo("home");
          }
        }
      );
    } else {
      this.handleFieldError(toLowerCase(username), password);
    }
  };

  showPassword = type =>
    this.setState(state => {
      return { [type]: !state[type] };
    });

  handleKeyDown = e => {
    let { username, password } = this.state;
    if (e.key === "Enter" && username && password) {
      this.signIn();
    }
  };

  render() {
    let { pass01type, loading } = this.state;
    return (
      <div className="container">
        <div className="row"></div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <span className={Welcome_to_AIRR}>
                  Welcome to <span className={text_style_1}>AIRR</span>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <span className={Start_an_Assessment}>
                  Start an Assessment and answer a series of questions to check
                  the readiness of your company to invest in Artificial
                  Intelligence.
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className={TopMargin}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 col-sm-12">
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      name="username"
                      className="form-control"
                      placeholder="Work Email"
                      onChange={this.handleOnChange}
                    />
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        name="password"
                        type={pass01type ? "password" : "text"}
                        className="form-control"
                        placeholder="Password"
                        onChange={this.handleOnChange}
                        onKeyDown={this.handleKeyDown}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <img
                            onClick={() => this.showPassword("pass01type")}
                            className="Show-password"
                            src="assets/show-password.png"
                            alt=""
                            srcSet="assets/show-password@2x.png 2x,
                  assets/show-password@3x.png 3x"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-4">
                <button id="btnLogin" className={Signin} onClick={this.signIn}>
                  Login
                  {loading ? (
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <img
                      className={Arrow}
                      src="assets/arrow-right.svg"
                      alt=""
                    />
                  )}
                </button>
              </div>
              <div className="col-md-6 col-sm-8">
                <Link to="/reset" className={Forgot_password}>
                  Forgot password
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className={TopMargin}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <span className={Dont_have_an_account}>
                  Don’t have an account ? {"   "}
                  <Link className={Signup} to="/register">
                    Signup
                  </Link>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.errorMessage ? (
                  <div className="alert alert-danger alert-with-icon">
                    <button
                      type="button"
                      aria-hidden="true"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="tim-icons icon-simple-remove"></i>
                    </button>
                    <span>
                      <b> Error! </b> {this.state.errorMessage}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <picture>
              <source
                className={Demo_2}
                srcSet="assets/Login_HD.webp"
                type="image/webp"
              />
              <source
                className={Demo_2}
                srcSet="assets/Login_HD.png"
                type="image/png"
              />
              <img className={Demo_2} src="assets/Login_HD.png" />
            </picture>
          </div>
        </div>
        <div className="row"></div>
      </div>
    );
  }
}

export default withToastManager(withRouter(Login));
