import React from "react";
import _ from "lodash";
import creditCardType from "credit-card-type";

function CreditCard({ card }) {
  if (!_.isNull(card) && !_.isUndefined(card) && !_.isEqual(card, "unknown")) {
    let icon = require(`payment-icons/min/flat/${card}.svg`);
    return (
      <img
        src={icon}
        alt="credit_card_type"
        style={{ width: "24px", height: "16px" }}
      />
    );
  }
  return null;
}

export default CreditCard;
