import React, { Component, Fragment } from "react";
import {
  Navigationbar,
  Logo,
  Name,
  Message,
  Userdetail,
  Account,
  BackSection,
  Home,
  Back
} from "./Navigation.module.scss";
import { SignOut } from "../Util/Auth";
import _ from "lodash";
import { connect } from "react-redux";
import { isAuthenticated } from "../Util/Auth";
import { withRouter, Link } from "react-router-dom";
import { GetUser } from "../Actions/UsersActions";

class Navigation extends Component {
  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  componentDidMount() {
    if (isAuthenticated()) {
      this.props.getUser();
    }
  }

  linkto = () => {
    return _.get(this.props, "type", false);
  };

  checkToHome = () => {
    let { pathname } = this.props.location;
    return (
      pathname === "/assesment" ||
      pathname === "/report" ||
      pathname === "/account"
    );
  };

  checkToLogin = () => {
    let { pathname } = this.props.location;
    return (
      pathname === "/account" ||
      pathname === "/report" ||
      pathname === "/assesment" ||
      pathname === "/home" ||
      pathname === "/"
    );
  };

  render() {
    let { type } = this.props;

    return (
      <Fragment>
        <nav className={`${Navigationbar} container`}>
          <div className="row">
            <div className="col-4 col-sm-4 col-md-2">
              {this.linkto() ? (
                <Link to="/">
                  <img
                    alt={"logo"}
                    className={Logo}
                    src="assets/logo-horizontal-flat.svg"
                  />
                </Link>
              ) : (
                <Link to="/home">
                  <img
                    alt={"logo"}
                    className={Logo}
                    src="assets/logo-horizontal-flat.svg"
                  />
                </Link>
              )}
            </div>
            <div className="col-3 col-sm-2 col-md-6">
              {this.checkToHome() ? (
                <Link to="/home">
                  <div className={BackSection}>
                    <img src="assets/home-white.svg" className={Home} />
                    <p className={Back}>Back to home</p>
                  </div>
                </Link>
              ) : null}
            </div>
            <div className="col-5 col-sm-6 col-md-4">
              {this.checkToLogin() ? (
                <div className="row">
                  <div className="col-8 col-sm-9 col-md-10">
                    <div className={Userdetail}>
                      <div className="row">
                        <div className="col-md-12">
                          <span className={Message}>Hello There</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <span className={Name}>
                            {isAuthenticated() ? (
                              _.get(this.props, "user.firstName", "") +
                              " " +
                              _.get(this.props, "user.lastName", "")
                            ) : (
                              <Link to="/login">
                                <p>Login or Signup</p>
                              </Link>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 col-sm-3 col-md-2">
                    <div className={Userdetail}>
                      <div className="dropdown">
                        <img
                          className={`${Account}  dropdown-toggle`}
                          src="assets/account.svg"
                          alt=""
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        />
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {isAuthenticated() ? (
                            <span
                              className="dropdown-item"
                              onClick={() => this.routeTo("account")}
                            >
                              Account
                            </span>
                          ) : null}
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              isAuthenticated()
                                ? SignOut(() => this.routeTo("login"))
                                : this.routeTo("login")
                            }
                          >
                            {isAuthenticated() ? "Logout" : "Login"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </nav>
        <Fragment>{this.props.children}</Fragment>
      </Fragment>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    user: state.user.user
  };
};

let mapDispatchToProps = dispatch => {
  return {
    getUser: () => {
      dispatch(GetUser());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navigation));
