import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { loadState } from "../Util/Persist";
import RootReducer from "../Reducers/RootReducer";
//load persisted state
const persistedState = loadState();
const middleware = [thunk];
const Compose =
  process.env.NODE_ENV === "development"
    ? compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : compose(applyMiddleware(thunk));

export default () => {
  return createStore(RootReducer, persistedState, Compose);
};
