import {
  GET_USERS,
  UPDATE_USER,
  LOADING,
  ERROR_USER,
  CLEAR_USER_STATE,
  UPDATE_NOTIFICATION
} from "../Constants/Users";

const initialState = {
  user: null,
  loading: false,
  error: false,
  errorMessage: "",
  updateSuccess: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USERS:
      return { ...state, user: payload, updateSuccess: false, loading: false };
    case UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...payload },
        updateSuccess: true,
        loading: false
      };
    case LOADING:
      return { ...state, loading: payload };
    case CLEAR_USER_STATE:
      return { ...initialState };
    case ERROR_USER:
      return { ...state, error: payload, errorMessage: "", loading: false };
    case UPDATE_NOTIFICATION:
      return { ...state, updateSuccess: payload };
    default:
      return state;
  }
};
