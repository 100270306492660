import React from "react";
import { Tab, Text, TextBold, PaymentIcon } from "./AssesmentTab.module.scss";

function AssesmentTab({ type, text, onClick, selected, done }) {
  return (
    <div
      className={Tab}
      onClick={onClick}
      style={{ backgroundColor: selected ? "#0a0c28" : null }}
    >
      <div className="contianer">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <div style={{ margin: "6px" }} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-2 col-2">
            <img
              src={done ? `assets/${type}-done.svg` : `assets/${type}.svg`}
              className={PaymentIcon}
              style={{ color: done ? "#96e6a1" : null }}
            />
          </div>
          <div className="col-md-9 col-sm-10 col-10">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <span
                  className={TextBold}
                  style={{ color: done ? "#96e6a1" : null }}
                >
                  {type}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div style={{ margin: "6px" }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <span
                  className={Text}
                  style={{ color: done ? "#96e6a1" : null }}
                >
                  {text}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssesmentTab;
