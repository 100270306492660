let CryptoJS = require("crypto-js");

/**
 * loadSate loads the redux store state
 * from the browser localstorage
 */
export const loadState = () => {
  try {
    const serializedState = CryptoJS.AES.decrypt(
      localStorage.getItem("airr_meta"),
      process.env.REACT_APP_ENCRYPT
    ).toString(CryptoJS.enc.Utf8);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

/**
 * saveState save the store state
 * to localstorage
 * @param {object} state
 */
export const saveState = state => {
  try {
    const serializedState = CryptoJS.AES.encrypt(
      JSON.stringify(state),
      process.env.REACT_APP_ENCRYPT
    ).toString();
    localStorage.setItem("airr_meta", serializedState);
  } catch (err) {
    throw new Error("Unable to fetch state from localstorage");
  }
};

/**
 * loadSelectedState loads the state
 * from the browser localstorage
 */
export const loadSelectedState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

/**
 * saveSelectedState save the state
 * to localstorage
 * @param {object} state
 */
export const saveSelectedState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    throw new Error("Unable to fetch state from localstorage");
  }
};
